import React, { useState } from 'react';
import { NavLink, withRouter, useNavigate } from "react-router-dom";
import LocalStorageHelper from '../utilities/LocalStorageHelper';

const SessionManager = React.createContext({
    token: null,
    isLoggenIn: false,
    login: (token) => { },
    logout: () => { }
})

export const SessionManagerProvider = (props) => {
    const initialToken = LocalStorageHelper.getToken();
    const [token, setToken] = useState(initialToken);
    const userIsLoggedIn = !!token;
    const history = useNavigate();

    const loginHandler = (token) => {
        LocalStorageHelper.saveToken(token)
        setToken(token)
        history.replace('/')
    }

    const logoutHandler = () => {
        LocalStorageHelper.removeToken();
        setToken(null)
        history.replace('/login')
    }

    const contextValue = {
        token: token,
        isLoggenIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }

    return <SessionManager.Provider value={contextValue}>
        {props.children}
    </SessionManager.Provider>
}

export const retryLazyImport = (fn, retriesLeft = 5, interval = 1000) => {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retryLazyImport(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

export default SessionManager;