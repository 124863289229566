import {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
} from "./apiClient";

//#region 
const ClinicUrl = 'clinics';
//#endregion 

const ClinicService = {
    getAllClinics: async (queryString) => {
        var _url = ClinicUrl + queryString;
        var res = await getRequest(_url);

        return res;
    },
    createClinic: async (param) => {
        var res = await postRequest(ClinicUrl, param);

        return res.data;
    },
    getClinicById: async (id) => {
        var _url = ClinicUrl + "/" + id;
        var res = await getRequest(_url);

        return res.data;
    },
    putClinic: async (param, id) => {
        var _url = ClinicUrl + "/" + id;
        var res = await putRequest(_url, param);

        return res;
    },
    deleteClinic: async (id) => {
        var _url = ClinicUrl + "/" + id;
        var res = await deleteRequest(_url);

        return res;
    },
}

export default ClinicService
