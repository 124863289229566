import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withCookies, useCookies } from 'react-cookie';
import './PageLayout.scss';
import { retryLazyImport } from '../../../../store/SessionManager';

import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';



import { 
    AccessEnum,
    QueueAccessEnum,
    PatientAccessEnum,
    ReportsAccessEnum,
    InvoicesAccessEnum,
    InventoryAccessEnum,
    AppointmentAccessEnum,
    MaintenanceAccessEnum,
    PrescriptionAccessEnum,
} from '../../../../utilities/Enums/AccesibilityEnum';

import GlobalFunctionUtils from '../../../../utilities/GlobalFunctionUtils';
import RootLayout from './RootLayout';
import ImportFile from '../../../../utilities/Helpers/ImportFileHelper';
import AccessHelper from '../../../../utilities/Helpers/AccessHelper';
import SettingsHelper from '../../../../utilities/Helpers/SettingsHelper';
import { RouteEnum } from '../../../../utilities/Enums/RouteEnums';
import LocalStorageHelper from '../../../../utilities/LocalStorageHelper';
import { RoleEnum } from '../../../../utilities/Enums/RoleEnum';
import { useQueryClient } from '@tanstack/react-query';

const Authentication = React.lazy(() => retryLazyImport(() => import('../../../Authentication/Authentication')));
const ForgotPassword = React.lazy(() => retryLazyImport(() => import('../../../Authentication/ForgotPassword')));

const ErrorPage = React.lazy(() => retryLazyImport(() => import('../../../ErrorPages/ErrorPage')));
const UnderDevelopmentPage = React.lazy(() => retryLazyImport(() => import('../../../ErrorPages/UnderDevelopmentPage')));
const Dashboard = React.lazy(() => retryLazyImport(() => import('../../../Dashboard/Dashboard')));
const DashboardLogo = React.lazy(() => retryLazyImport(() => import('../../../Dashboard/DashboardLogoView')));
const AdminDashboard = React.lazy(() => retryLazyImport(() => import('../../../Dashboard/DashboardDetails/AdminDashboard')));
const DoctorDashboard = React.lazy(() => retryLazyImport(() => import('../../../Dashboard/DashboardDetails/DoctorDashboard')));
const DefaultDashboard = React.lazy(() => retryLazyImport(() => import('../../../Dashboard/DashboardDetails/DefaultDashboard')));
// const Dashboard = React.lazy(() => retryLazyImport(() => import('../../../Dashboard/DashboardLogoView')));
// Queue Menu
const Queue = React.lazy(() => retryLazyImport(() => import('../../../Queue/Queue')));
const PastQueue = React.lazy(() => retryLazyImport(() => import('../../../Queue/PastQueue/PastQueue')));
//Patients Menu
const Patients = React.lazy(() => retryLazyImport(() => import('../../../Patients/Patients')));
const NewRegisteredPatients = React.lazy(() => retryLazyImport(() => import('../../../Patients/NewRegisteredPatients')));
const NewPatient = React.lazy(() => retryLazyImport(() => import('../../../Patients/NewPatient/NewPatient')));
const PatientDetails = React.lazy(() => retryLazyImport(() => import('../../../Patients/PatientDetails/PatientDetails')));

// Appointment Menu
const Appointment = React.lazy(() => retryLazyImport(() => import('../../../Appointment/Appointment')));
const AppointmentCalendar = React.lazy(() => retryLazyImport(() => import('../../../Appointment/Calendar/AppointmentCalendar')));
// Prescription Menu
const Prescription = React.lazy(() => retryLazyImport(() => import('../../../Prescription/Prescription')));
const PrescriptionDetails = React.lazy(() => retryLazyImport(() => import('../../../Prescription/PrescriptionDetails/PrescriptionDetails')));
// Invoices Menu
const Invoices = React.lazy(() => retryLazyImport(() => import('../../../Invoices/Invoices/Invoices')));
const InvoiceForm = React.lazy(() => retryLazyImport(() => import('../../../Invoices/Invoices/InvoiceForm/InvoiceForm')));
const InvoicesDetailedSummary = React.lazy(() => retryLazyImport(() => import('../../../Invoices/InvoicesDetailedSummary/InvoicesDetailedSummary')));
const GroupInvoices = React.lazy(() => retryLazyImport(() => import('../../../Invoices/GroupInvoices/GroupInvoices')));
const PostedGroupInvoices = React.lazy(() => retryLazyImport(() => import('../../../Invoices/PostedGroupInvoices/PostedGroupInvoices')));
const PostedGroupInvoicesDetails = React.lazy(() => retryLazyImport(() => import('../../../Invoices/PostedGroupInvoices/PostedGroupInvoicesDetails/PostedGroupInvoicesDetails')));
const Quotation = React.lazy(() => retryLazyImport(() => import('../../../Invoices/Quotation/Quotation')));
const QuotationDetails = React.lazy(() => retryLazyImport(() => import('../../../Invoices/Quotation/QuotationDetails/QuotationDetails')));
const CreditNote = React.lazy(() => retryLazyImport(() => import('../../../Invoices/CreditNote/CreditNote')));
const CreditInvoiceDetails = React.lazy(() => retryLazyImport(() => import('../../../Invoices/CreditNote/CreditInvoiceDetails/CreditInvoiceDetails')));

// Inventory Menu
const Inventory = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Inventory/Inventory')));
const InventoryDetails = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Inventory/InventoryDetails/InventoryDetails')));
const ReceivableOrder = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Vendor/ReceivableOrder/ReceivableOrder')));
const ReceivableOrderDetails = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Vendor/ReceivableOrder/ReceivableOrderDetails/ReceivableOrderDetails')));
const PurchaseOrder = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Vendor/PurchaseOrder/PurchaseOrder')));
const PurchaseOrderDetails = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Vendor/PurchaseOrder/PurchaseOrderDetails/PurchaseOrderDetails')));
const InternalStockTransfer = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Branch/InternalStockTransfer/InternalStockTransfer')));
const InternalStockTransferDetails = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Branch/InternalStockTransfer/InternalStockTransferDetails/InternalStockTransferDetails')));
const InternalStockReceive = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Branch/InternalStockReceive/InternalStockReceive')));
const PurchaseReturn = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Vendor/PurchaseReturn/PurchaseReturn')));
const PurchaseReturnDetails = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Vendor/PurchaseReturn/PurchaseReturnDetails/PurchaseReturnDetails')));
const GoodsReturn = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Branch/GoodsReturn/GoodsReturn')));
const GoodsReturnDetails = React.lazy(() => retryLazyImport(() => import('../../../Inventory/Branch/GoodsReturn/GoodsReturnDetails/GoodsReturnDetails')));

// Reports Menu
const MedicalCertificateReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/MedicalCertificate/MedicalCertificateReport')));
const PatientsReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/PatientsReport/PatientsReport')));
const PaymentsReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/PaymentsReport/PaymentsReport')));
const AgingsReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/AgingsReport/AgingsReport')));
const ClinicServicesInventoryReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/ClinicServicesInventoryReport/ClinicServicesInventoryReport')));
const AppointmentsReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/AppointmentsReport/AppointmentsReport')));
const QueueReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/QueueReport/QueueReport')));
const InventoryReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/InventoryReport/InventoryReport')));
const StockAdjustmentReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/StockAdjustmentReport/StockAdjustmentReport')));
const EndOfDayReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/EndOfDayReport/EndOfDayReport')));
const InvoicesReport = React.lazy(() => retryLazyImport(() => import('../../../Reports/InvoicesReport/InvoicesReport')));
const ReportSummary = React.lazy(() => retryLazyImport(() => import('../../../Reports/InvoicesReport/ReportSummary/ReportSummary')));

// Maintenance Menu
const ClinicMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/ClinicMaintenance/ClinicMaintenance')));
const ClinicMaintenanceForm = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/ClinicMaintenance/ClinicMaintenanceForm/ClinicMaintenanceForm')));
const ServiceMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/ServiceMaintenance/ServiceMaintenance')));
const ServiceMaintenanceDetails = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/ServiceMaintenance/ServiceMaintenanceDetails/ServiceMaintenanceDetails')));
const RoleAndAccessibilityMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/RoleAndAccessibilityMaintenance/RoleAndAccessibilityMaintenance')));
const RoleAndAccessibilityDetails = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/RoleAndAccessibilityMaintenance/RoleAndAccessibilityDetails/RoleAndAccessibilityDetails')));
const ServiceCategoryMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/ServiceCategoryMaintenance/ServiceCategoryMaintenance')));
// const PriceGroupMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/PriceGroupMaintenance/PriceGroupMaintenance')));
const SupplierMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/SupplierMaintenance/SupplierMaintenance')));
const SupplierMaintenanceDetails = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/SupplierMaintenance/SupplierMaintenanceDetails/SupplierMaintenanceDetails')));
const InsurancePanelMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/InsurancePanelMaintenance/InsurancePanelMaintenance')));
const InsurancePanelMaintenanceDetails = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/InsurancePanelMaintenance/InsurancePanelMaintenanceDetails/InsurancePanelMaintenanceDetails')));
const PriceMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/PriceMaintenance/PriceMaintenance')));
const UserMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/UserMaintenance/UserMaintenance')));
const UserMaintenanceForm = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/UserMaintenance/UserMaintenanceForm/UserMaintenanceForm')));
const GeneralSettings = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/GeneralSettings/GeneralSettings')));
const InventoryMaintenance = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/InventoryMaintenance/InventoryMaintenance')));
const InventorySetup = React.lazy(() => retryLazyImport(() => import('../../../Maintenance/InventorySetup/InventorySetup')));

// Print and Scan
const PrintInvoicePage = React.lazy(() => retryLazyImport(() => import('../../../Print/Invoice/PrintInvoicePage')));
const PrintPurchaseReturnPage = React.lazy(() => retryLazyImport(() => import('../../../Print/Inventory/PrintPurchaseReturnPage')));
const PrintGoodsReturnPage = React.lazy(() => retryLazyImport(() => import('../../../Print/Inventory/PrintGoodsReturnPage')));
const PrintPostedGroupInvoicePage = React.lazy(() => retryLazyImport(() => import('../../../Print/PostedGroupInvoice/PrintPostedGroupInvoicePage')));
const PrintGroupInvoicePage = React.lazy(() => retryLazyImport(() => import('../../../Print/GroupInvoice/PrintGroupInvoicePage')));
const PrintMedicalCertPage = React.lazy(() => retryLazyImport(() => import('../../../Print/MedicalCertificate/PrintMedicalCertPage')));
const PrintOfficialReceiptPage = React.lazy(() => retryLazyImport(() => import('../../../Print/Receipt/PrintOfficialReceipt')));
const PrintPrescriptionPage = React.lazy(() => retryLazyImport(() => import('../../../Print/Prescription/PrintPrescription')));
const PrintPrescriptionLabelPage = React.lazy(() => retryLazyImport(() => import('../../../Print/Prescription/PrintPrescriptionLabel')));
const PrintRecordLabelPage = React.lazy(() => retryLazyImport(() => import('../../../Print/RecordLabel/PrintRecordLabel')));
const PrintTimeSlipPage = React.lazy(() => retryLazyImport(() => import('../../../Print/TimeSlip/PrintTimeSlip')));
const PrintPurchaseOrderPage = React.lazy(() => retryLazyImport(() => import('../../../Print/PurchaseOrder/PrintPurchaseOrderPage')));
const PrintReceiveOrderPage = React.lazy(() => retryLazyImport(() => import('../../../Print/ReceiveOrder/PrintReceiveOrderPage')));
const PrintCreditNotePage = React.lazy(() => retryLazyImport(() => import('../../../Print/CreditNote/PrintCreditNotePage')));
const PrintReceipt = React.lazy(() => retryLazyImport(() => import('../../../Print/Receipt/PrintReceipt')));
const PrintQuotation = React.lazy(() => retryLazyImport(() => import('../../../Print/Quotation/PrintQuotation')));
const PrintStockTransfer = React.lazy(() => retryLazyImport(() => import('../../../Print/StockTransfer/PrintStockTransfer')));
const PrintStockReceived = React.lazy(() => retryLazyImport(() => import('../../../Print/StockReceive/PrintStockReceive')));
const PrintEODReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/EndOfDayReport/PrintEODReport')));
const PrintMedicalReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/MedicalReport/PrintMedicalReport')));
const PrintQueueReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/QueueReport/PrintQueueReport')));
const PrintAppointmentsReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/AppointmentsReport/PrintAppointmentsReport')));
const PrintClinincalServicesInventoryReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/ClinincalServicesInventoryReport/PrintClinincalServicesInventoryReport')));
const PrintAgingsReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/AgingsReport/PrintAgingsReport')));
const PrintPaymentsReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/PaymentsReport/PrintPaymentsReport')));
const PrintPatientsReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/PatientsReport/PrintPatientsReport')));
const PrintInvoiceReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/InvoiceReport/PrintInvoiceReport')));
const PrintInventoryReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/InventoryReport/PrintInventoryReport')));
const PrintStockAdjustmentReport = React.lazy(() => retryLazyImport(() => import('../../../Print/Reports/StockAdjustmentReport/PrintStockAdjustmentReport')));

const PageLayout = ({
    darkMode = false,
    setDarkMode = null,
    updateActiveTab = null,
    toggledMenu = false,
    setToggledMenu = null,
    loadingState = false,
    setLoadingState = null
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const userData = LocalStorageHelper.getUserData();
    const settingsData = LocalStorageHelper.getGeneralSettingsData();
    const queryClient = useQueryClient();

    //caching
    // const { status, data: clinicData, error, isLoading, isPreviousData, fetchStatus } = useQuery({
    //     queryKey: ['get_clinic_details_by_id', userData?.clinicId ?? '0'],
    //     queryFn: () => getClinicById(),
    //     keepPreviousData: true,
    //     // enabled: userData.clinicId && !queryClient.getQueryData(['get_clinic_details_by_id', userData?.clinicId ?? '0']),
    //     staleTime: 1 * 60 * 1000, cacheTime: 5 * 60 * 1000
    // })

    // Use states
    const [anchorDrawer, setAnchorDrawer] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [IsAutoHideSnackbar, setIsAutoHideSnackbar] = useState(true);
    const [UpdateList, setUpdateList] = useState(false);
    const [pageDetails, setPageDetails] = useState({
        pageId: "",
        patientId: "",
        pageParams: [],
        idType: "",
        pageTab: "",
        pageItemDetailsFromPage: "",

    });

    //Variables
    const [cookies, setCookie, removeCookie] = useCookies(['navigatedPages']);
    const isLoggedIn = LocalStorageHelper.isLoggedIn();

    let contentPageProps = {
        darkMode: darkMode,
        loadingState: loadingState,
        setLoadingState: setLoadingState,
        // IsAutoHideSnackbar: IsAutoHideSnackbar,
        // setIsAutoHideSnackbar: setIsAutoHideSnackbar,
        navigatedPages: cookies.navigatedPages,
        DefaultGeneralSettings: settingsData,
    },
        breadcrumbsProps = {
            darkMode: darkMode,
            navigatedPages: cookies.navigatedPages,
        },
    authenticationProps = {
        loadingState: loadingState,
        setLoadingState: setLoadingState,
        DefaultGeneralSettings: settingsData,
    };

    //Event Handlers

    const importFile = async (target, module) => {
        let importName = "";

        switch (module) {
            case "patients":
                importName = "Patients";
                break;

            case "users":
                importName = "Users";
                break;

            case "clinicalServices":
                importName = "Clinical Services";
            break;

            case "inventory":
                importName = "Inventories";
            break;

            case "inventoryMaster":
                importName = "Master Inventories";
            break;

            case "suppliers":
                importName = "Suppliers";
            break;
            case "insurances":
                importName = "Insurances/Panels";
            break;
        }

        if (target.files.length > 0) {
            const file = target.files[0];
            const fileName = (file.name != null && file.name != "" ? file.name : "");
            const fileExt = (fileName != null && fileName != "" ? GlobalFunctionUtils.getFileExtension(file.name) : "");

            if (fileExt != null && fileExt != "" && (fileExt == "csv" || fileExt == "xlsx")) {
                enqueueSnackbar("Importing " + importName + "...", { variant: 'info' });
                setIsAutoHideSnackbar(false);

                var importResponse = await ImportFile.Import(file, module);

                enqueueSnackbar(importResponse.msg, { variant: importResponse.success ? 'success' : 'error' });
                setIsAutoHideSnackbar(true);

                setUpdateList(true);
            } else {
                enqueueSnackbar("Import " + importName + " Failed :: Must be a csv or excel file", { variant: 'error' });
                // setOpenSnackbar(true);
                // setIsAutoHideSnackbar(true);
            }
        }
    };

    contentPageProps = {
        ...contentPageProps,
        importFile: importFile,
        UpdateList: UpdateList,
        setUpdateList: setUpdateList,
    }
    breadcrumbsProps = {
        ...breadcrumbsProps,
    }
    

    useEffect(() => {
        SettingsHelper.UpdateFavIcon();
    }, []);


    // const selectedPageTab = (pageDetails != null && pageDetails.pageTab != null && pageDetails.pageTab != "" ?
    //     GlobalFunctionUtils.decryptText(pageDetails.pageTab) : "patientInfo"
    // );
    // const patientDetailsElementPage = (selectedPageTab == "medicalRecords" ?
    //     <MedicalRecords /> : ((selectedPageTab == "appointmentHistory" || selectedPageTab == "invoiceHistory" || selectedPageTab == "prescriptionHistory" || selectedPageTab == "dispensingHistory") ?
    //         <PatientHistory /> : (selectedPageTab == "drugAllergyReaction" ?
    //             <PatientDrugAllergyReaction /> : <PatientDetails />
    //         )
    //     )

    // );

    const router = createBrowserRouter([
        {
            path: RouteEnum.LOGIN,
            element: <Authentication {...authenticationProps} />
        },
        {
            path: RouteEnum.FORGOT_PASSWORD,
            element: <ForgotPassword {...authenticationProps} />
        },
        {
            path: '/',
            element:  isLoggedIn ? <RootLayout darkMode={darkMode}
                setDarkMode={setDarkMode}
                updateActiveTab={updateActiveTab}
                toggledMenu={toggledMenu}
                setToggledMenu={setToggledMenu}
                loadingState={loadingState}
                setLoadingState={setLoadingState}
                DefaultGeneralSettings={settingsData}
            // IsAutoHideSnackbar={IsAutoHideSnackbar}
            // setIsAutoHideSnackbar={setIsAutoHideSnackbar}
            />
            : <Authentication {...authenticationProps} />,
            errorElement: <RootLayout darkMode={darkMode}
                setDarkMode={setDarkMode}
                updateActiveTab={updateActiveTab}
                toggledMenu={toggledMenu}
                setToggledMenu={setToggledMenu}
                loadingState={loadingState}
                setLoadingState={setLoadingState}
                DefaultGeneralSettings={settingsData}
                errorOutlet={<ErrorPage />}
            />,
            children: [
                // { path: RouteEnum.DASHBOARD, element: (AccessHelper.hasAccess(QueueAccessEnum.DASHBOARD) ? <Dashboard {...contentPageProps} /> : <ErrorPage /> )},
                // { path: RouteEnum.DASHBOARD, element: <Dashboard {...contentPageProps} /> },
                { path: RouteEnum.DASHBOARD, element: AccessHelper.hasAccess(AccessEnum.DASHBOARD) && (userData && userData.roleId && (userData.roleId == RoleEnum.SUPER_ADMIN || userData.roleId == RoleEnum.LEAD_ADMIN)) ? 
                    <AdminDashboard {...contentPageProps} /> : (AccessHelper.hasAccess(AccessEnum.DASHBOARD) && (userData && userData.roleId && (userData.roleId == RoleEnum.DOCTOR)) ? 
                        <DoctorDashboard {...contentPageProps} /> : ((userData && userData.roleId && AccessHelper.hasAccess(AccessEnum.DASHBOARD) ? 
                            <DefaultDashboard {...contentPageProps} /> : <DashboardLogo {...contentPageProps} />)))},
                { path: RouteEnum.LOGIN, element: <Authentication {...contentPageProps} /> },
                /* Queue Menu */
                // { path: RouteEnum.QUEUE, element: <Queue {...contentPageProps} /> },
                // { path: RouteEnum.TODAY_QUEUE_LIST, element: <Queue {...contentPageProps} /> },
                // { path: RouteEnum.PAST_QUEUE_LIST, element: <PastQueue {...contentPageProps} /> },
                { path: RouteEnum.QUEUE, element: (AccessHelper.hasAccess(QueueAccessEnum.QUEUE_TODAY_VIEW)  ?  
                    <Queue {...contentPageProps} />  : <ErrorPage />
                ) },
                { path: RouteEnum.TODAY_QUEUE_LIST, element: (AccessHelper.hasAccess(QueueAccessEnum.QUEUE_TODAY_VIEW)  ?  
                    <Queue {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PAST_QUEUE_LIST, element: (AccessHelper.hasAccess(QueueAccessEnum.QUEUE_PAST_VIEW)  ?  
                    <PastQueue {...contentPageProps} /> : <ErrorPage />
                ) },
                /* Patients Menu */
                // { path: RouteEnum.PATIENT_LIST, element: <Patients {...contentPageProps} /> },
                // { path: RouteEnum.NEW_REGISTERED_PATIENT_LIST, element: <NewRegisteredPatients {...contentPageProps} /> },
                // { path: RouteEnum.NEW_PATIENT, element: <NewPatient {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS_DRUG_ALLERGY + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS_INFO + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS_APPOINTMENT + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS_INVOICE + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS_PRESCRIPTION + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_DETAILS_DISPENSING + '/:patientid', element: <PatientDetails {...contentPageProps} /> },
                { path: RouteEnum.PATIENT_LIST, element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <Patients {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.NEW_REGISTERED_PATIENT_LIST, element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_NEW_VIEW)  ?  
                    <NewRegisteredPatients {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.NEW_PATIENT, element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_NEW_VIEW)  ?  
                    <NewPatient {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: '/PatientDetails', element: <PatientDetails {...contentPageProps} /> },
                { path: RouteEnum.PATIENT_DETAILS + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_DETAILS_DRUG_ALLERGY + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_DETAILS_INFO + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_DETAILS_APPOINTMENT + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_DETAILS_INVOICE + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_DETAILS_PRESCRIPTION + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_DETAILS_DISPENSING + '/:patientid', element: (AccessHelper.hasAccess(PatientAccessEnum.PATIENT_LIST_VIEW)  ?  
                    <PatientDetails {...contentPageProps} /> : <ErrorPage />
                ) },

                /* Appointment Menu */
                // { path: RouteEnum.APPOINTMENT_LIST, element: <Appointment {...contentPageProps} /> },
                // { path: '/PatientAppointment/:id', element: <Appointment {...contentPageProps} /> },
                // { path: '/AppointmentCalendar', element: <AppointmentCalendar {...contentPageProps} /> },
                { path: RouteEnum.APPOINTMENT_LIST, element: (AccessHelper.hasAccess(AppointmentAccessEnum.APPOINTMENT_LIST_VIEW)  ?  
                    <Appointment {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: '/PatientAppointment/:id', element: (AccessHelper.hasAccess(AppointmentAccessEnum.APPOINTMENT_LIST_VIEW)  ?  
                    <Appointment {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: '/AppointmentCalendar', element: (AccessHelper.hasAccess(AppointmentAccessEnum.APPOINTMENT_CALENDAR_VIEW)  ?  
                    <AppointmentCalendar {...contentPageProps} /> : <ErrorPage />
                ) },

                /* Prescription Menu */
                // { path: RouteEnum.PRESCRIPTION_LIST, element: <Prescription {...contentPageProps} /> },
                // { path: RouteEnum.PRESCRIPTION_DETAILS + '/:prescriptionid', element: <PrescriptionDetails {...contentPageProps} /> },
                { path: RouteEnum.PRESCRIPTION_LIST, element: (AccessHelper.hasAccess(PrescriptionAccessEnum.PRESCRIPTION_VIEW)  ?  
                    <Prescription {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PRESCRIPTION_DETAILS + '/:prescriptionid', element: (AccessHelper.hasAccess(PrescriptionAccessEnum.PRESCRIPTION_VIEW)  ?  
                    <PrescriptionDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                // { path: '/PrintPrescriptionLabel/:patientid', element: <PrintPrescriptionLabel {...contentPageProps} /> },

                /* Invoices Menu */
                // { path: RouteEnum.INVOICE_LIST, element: <Invoices {...contentPageProps} /> },
                // { path: RouteEnum.INVOICE_DETAILS + "/:invoiceid", element: <InvoiceForm {...contentPageProps} /> },
                // { path: RouteEnum.INVOICE_DETAILED_SUMMARY, element: <InvoicesDetailedSummary {...contentPageProps} /> },
                // { path: RouteEnum.GROUP_INVOICE_LIST, element: <GroupInvoices {...contentPageProps} /> },
                // { path: RouteEnum.POSTED_GROUP_INVOICE_LIST, element: <PostedGroupInvoices {...contentPageProps} /> },
                // { path: RouteEnum.POSTED_GROUP_INVOICE_DETAILS + "/:invoiceid", element: <PostedGroupInvoicesDetails {...contentPageProps} /> },
                // { path: RouteEnum.QOUTATION_LIST, element: <Quotation {...contentPageProps} /> },
                // { path: RouteEnum.QUOTATION_DETAILS + "/:invoiceid", element: <QuotationDetails {...contentPageProps} /> },
                // { path: RouteEnum.CREDIT_NOTE_LIST, element: <CreditNote {...contentPageProps} /> },
                // { path: RouteEnum.CREDIT_NOTE_DETAILS + "/:invoiceid", element: <CreditInvoiceDetails {...contentPageProps} /> },
                { path: RouteEnum.INVOICE_LIST, element: (AccessHelper.hasAccess(InvoicesAccessEnum.INVOICES_VIEW)  ?  
                    <Invoices {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INVOICE_DETAILS + "/:invoiceid", element: (AccessHelper.hasAccess(InvoicesAccessEnum.INVOICES_VIEW)  ?  
                    <InvoiceForm {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INVOICE_DETAILED_SUMMARY, element: (AccessHelper.hasAccess(InvoicesAccessEnum.INVOICES_DETAILED_VIEW)  ?  
                    <InvoicesDetailedSummary {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.GROUP_INVOICE_LIST, element: (AccessHelper.hasAccess(InvoicesAccessEnum.GROUP_INVOICES_VIEW)  ?  
                    <GroupInvoices {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.POSTED_GROUP_INVOICE_LIST, element: (AccessHelper.hasAccess(InvoicesAccessEnum.POSTED_GROUP_INVOICES_VIEW)  ?  
                    <PostedGroupInvoices {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.POSTED_GROUP_INVOICE_LIST, element: (AccessHelper.hasAccess(InvoicesAccessEnum.POSTED_GROUP_INVOICES_VIEW)  ?  
                    <PostedGroupInvoices {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.POSTED_GROUP_INVOICE_DETAILS + "/:invoiceid", element: (AccessHelper.hasAccess(InvoicesAccessEnum.POSTED_GROUP_INVOICES_VIEW)  ?  
                    <PostedGroupInvoicesDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.QOUTATION_LIST, element: (AccessHelper.hasAccess(InvoicesAccessEnum.QUOTATION_VIEW)  ?  
                    <Quotation {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.QUOTATION_DETAILS + "/:invoiceid", element: (AccessHelper.hasAccess(InvoicesAccessEnum.QUOTATION_VIEW)  ?  
                    <QuotationDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.CREDIT_NOTE_LIST, element: (AccessHelper.hasAccess(InvoicesAccessEnum.CREDIT_NOTE_VIEW)  ?  
                    <CreditNote {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.CREDIT_NOTE_DETAILS + "/:invoiceid", element: (AccessHelper.hasAccess(InvoicesAccessEnum.CREDIT_NOTE_VIEW)  ?  
                    <CreditInvoiceDetails {...contentPageProps} /> : <ErrorPage />
                ) },

                /* Inventory Menu */
                // { path: RouteEnum.INVENTORY_LIST, element: <Inventory  {...contentPageProps} /> },
                // { path: RouteEnum.INVENTORY_DETAILS + '/:inventoryid', element: <InventoryDetails {...contentPageProps} /> },
                // { path: RouteEnum.RECEIVABLE_ORDER_LIST, element: <ReceivableOrder {...contentPageProps} /> },
                // { path: RouteEnum.RECEIVABLE_ORDER_DETAILS + '/:orderid', element: <ReceivableOrderDetails {...contentPageProps} /> },
                // { path: RouteEnum.PURCHASE_ORDER_LIST, element: <PurchaseOrder {...contentPageProps} /> },
                // { path: RouteEnum.PURCHASE_ORDER_DETAILS + '/:orderid', element: <PurchaseOrderDetails {...contentPageProps} /> },
                // { path: RouteEnum.INTERNAL_STOCK_TRANSFER_LIST, element: <InternalStockTransfer {...contentPageProps} /> },
                // { path: RouteEnum.INTERNAL_STOCK_TRANSFER_DETAILS + '/:transferid', element: <InternalStockTransferDetails {...contentPageProps} /> },
                // { path: RouteEnum.INTERNAL_STOCK_RECEIVE_LIST, element: <InternalStockReceive {...contentPageProps} /> },
                // { path: RouteEnum.INTERNAL_STOCK_RECEIVE_DETAILS + '/:transferid', element: <InternalStockTransferDetails {...contentPageProps} /> },
                // { path: RouteEnum.PURCHASE_RETURN_LIST, element: <PurchaseReturn {...contentPageProps} /> },
                // { path: RouteEnum.PURCHASE_RETURN_DETAILS + '/:purchasereturnid', element: <PurchaseReturnDetails {...contentPageProps} /> },
                // { path: RouteEnum.BRANCH_GOODS_RETURN_LIST, element: <GoodsReturn {...contentPageProps} /> },
                // { path: RouteEnum.BRANCH_GOODS_RETURN_DETAILS + '/:goodsreturnid', element: <GoodsReturnDetails {...contentPageProps} /> },
                { path: RouteEnum.INVENTORY_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.INVENTORY_VIEW)  ? 
                    <Inventory  {...contentPageProps} /> : <ErrorPage />
                )},
                { path: RouteEnum.INVENTORY_DETAILS + '/:inventoryid', element: (AccessHelper.hasAccess(InventoryAccessEnum.INVENTORY_VIEW)  ?   
                    <InventoryDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.RECEIVABLE_ORDER_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_GOODS_RECEIVED_VIEW)  ?   
                    <ReceivableOrder {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.RECEIVABLE_ORDER_DETAILS + '/:orderid', element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_GOODS_RECEIVED_VIEW)  ?   
                    <ReceivableOrderDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PURCHASE_ORDER_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_PURCHASE_ORDER_VIEW)  ?   
                    <PurchaseOrder {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PURCHASE_ORDER_DETAILS + '/:orderid', element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_PURCHASE_ORDER_VIEW)  ?   
                    <PurchaseOrderDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INTERNAL_STOCK_TRANSFER_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_PURCHASE_ORDER_VIEW)  ?   
                    <InternalStockTransfer {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INTERNAL_STOCK_TRANSFER_DETAILS + '/:transferid', element: (AccessHelper.hasAccess(InventoryAccessEnum.BRANCH_STOCK_TRANSFER_VIEW)  ?   
                    <InternalStockTransferDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INTERNAL_STOCK_RECEIVE_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.BRANCH_GOODS_RECEIVED_VIEW)  ?   
                    <InternalStockReceive {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INTERNAL_STOCK_RECEIVE_DETAILS + '/:transferid', element: (AccessHelper.hasAccess(InventoryAccessEnum.BRANCH_GOODS_RECEIVED_VIEW)  ?   
                    <InternalStockTransferDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PURCHASE_RETURN_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_PURCHASE_RETURN_VIEW)  ?   
                    <PurchaseReturn {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PURCHASE_RETURN_DETAILS + '/:purchasereturnid', element: (AccessHelper.hasAccess(InventoryAccessEnum.VENDOR_PURCHASE_RETURN_VIEW)  ?   
                    <PurchaseReturnDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.BRANCH_GOODS_RETURN_LIST, element: (AccessHelper.hasAccess(InventoryAccessEnum.BRANCH_GOODS_RETURN_VIEW)  ?   
                    <GoodsReturn {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.BRANCH_GOODS_RETURN_DETAILS + '/:goodsreturnid', element: (AccessHelper.hasAccess(InventoryAccessEnum.BRANCH_GOODS_RETURN_VIEW)  ?  
                    <GoodsReturnDetails {...contentPageProps} /> : <ErrorPage />
                ) },

                /* Reports Menu */
                // { path: RouteEnum.MEDICAL_CERTIFICATE_REPORT, element: <MedicalCertificateReport {...contentPageProps} /> },
                // { path: RouteEnum.PATIENT_REPORT, element: <PatientsReport {...contentPageProps} /> },
                // { path: RouteEnum.PAYMENT_REPORT, element: <PaymentsReport {...contentPageProps} /> },
                // { path: RouteEnum.AGINGS_REPORT, element: <AgingsReport {...contentPageProps} /> },
                // { path: RouteEnum.CLINICAL_SERVICES_INVENTORY_REPORT, element: <ClinicServicesInventoryReport {...contentPageProps} /> },
                // { path: RouteEnum.APPOINTMENT_REPORT, element: <AppointmentsReport {...contentPageProps} /> },
                // { path: RouteEnum.QUEUE_REPORT, element: <QueueReport {...contentPageProps} /> },
                // { path: RouteEnum.DISPENSE_REPORT, element: <InventoryReport {...contentPageProps} /> },
                // {path: RouteEnum.STOCK_ADJUSTMENT_REPORT, element: <StockAdjustmentReport {...contentPageProps} /> },
                // { path: RouteEnum.END_OF_DAY_REPORT, element: <EndOfDayReport {...contentPageProps} /> },
                // { path: RouteEnum.INVOICE_REPORT, element: <InvoicesReport {...contentPageProps} /> },
                { path: RouteEnum.MEDICAL_CERTIFICATE_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_MEDICAL_CERIFICATE_VIEW)  ? 
                    <MedicalCertificateReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PATIENT_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_PATIENTS_VIEW)  ?  
                    <PatientsReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.PAYMENT_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_PAYMENTS_VIEW)  ?  
                    <PaymentsReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.AGINGS_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_AGINGS_VIEW)  ?  
                    <AgingsReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.CLINICAL_SERVICES_INVENTORY_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_CLINIC_SERIVCES_VIEW)  ?  
                    <ClinicServicesInventoryReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.APPOINTMENT_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_APPOINTMENTS_VIEW)  ?  
                    <AppointmentsReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.QUEUE_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_QUEUE_VIEW)  ?  
                    <QueueReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INVENTORY_REPORT, element: <InventoryReport {...contentPageProps} /> },
                { path: RouteEnum.DISPENSE_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_DISPENSE_VIEW)  ?  
                    <InventoryReport {...contentPageProps} /> : <ErrorPage />
                ) },
                {path: RouteEnum.STOCK_ADJUSTMENT_REPORT, element: (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_STOCK_ADJUSTMENT_VIEW) ?
                    <StockAdjustmentReport {...contentPageProps} /> : <ErrorPage />
                )},
                { path: RouteEnum.END_OF_DAY_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_ENDOFDAY_VIEW)  ?  
                    <EndOfDayReport {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INVOICE_REPORT, element:  (AccessHelper.hasAccess(ReportsAccessEnum.REPORT_INVOICE_VIEW)  ? 
                    <InvoicesReport {...contentPageProps} />  : <ErrorPage />
                ) },
                { path: RouteEnum.REPORT_SUMMARY, element: <ReportSummary {...contentPageProps} /> },

                /* Maintenance Menu */
                // { path: RouteEnum.GENERAL_SETTINGS, element: <GeneralSettings {...contentPageProps} /> },
                // { path: RouteEnum.ROLE_MAINTENANCE_LIST, element: <RoleAndAccessibilityMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.ROLE_MAINTENANCE_DETAILS + "/:roleid", element:  <RoleAndAccessibilityDetails {...contentPageProps} /> },
                // { path: RouteEnum.CLINIC_MAINTENANCE_LIST, element: <ClinicMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.CLINIC_MAINTENANCE_DETAILS + '/:clinicid', element: <ClinicMaintenanceForm {...contentPageProps} /> },
                // { path: RouteEnum.INSURANCE_MAINTENANCE_LIST, element: <InsurancePanelMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.INSURANCE_MAINTENANCE_DETAILS + '/:insuranceid', element: <InsurancePanelMaintenanceDetails {...contentPageProps} /> },
                // { path: RouteEnum.SUPPLIER_MAINTENANCE_LIST, element: <SupplierMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.SUPPLIER_MAINTENANCE_DETAILS + '/:supplierid', element: <SupplierMaintenanceDetails {...contentPageProps} /> },
                // { path: RouteEnum.USER_MAINTENANCE_LIST, element: <UserMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.USER_MAINTENANCE_DETAILS + '/:userid', element: <UserMaintenanceForm {...contentPageProps} /> },
                // { path: RouteEnum.SERVICE_MAINTENANCE_LIST, element: <ServiceMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.SERVICE_MAINTENANCE_DETAILS + '/:serviceid', element: <ServiceMaintenanceDetails {...contentPageProps} /> },
                // { path: RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST, element: <ServiceCategoryMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.INVENTORY_MAINTENANCE_LIST, element: <InventoryMaintenance {...contentPageProps} /> },
                // { path: RouteEnum.INVENTORY_MAINTENANCE_DETAILS + '/:inventoryid', element: <InventoryDetails {...contentPageProps} /> },
                // { path: RouteEnum.INVENTORY_SETUP_LIST, element: <InventorySetup {...contentPageProps} /> },
                { path: RouteEnum.GENERAL_SETTINGS, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_GENERAL_SETTINGS_VIEW)  ? 
                    <GeneralSettings {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.ROLE_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_ROLE_ACCESS_VIEW)  ? 
                    <RoleAndAccessibilityMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.ROLE_MAINTENANCE_DETAILS + "/:roleid", element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_ROLE_ACCESS_VIEW)  ? 
                    <RoleAndAccessibilityDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.CLINIC_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_CLINIC_VIEW)  ? 
                    <ClinicMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.CLINIC_MAINTENANCE_DETAILS + '/:clinicid', element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_CLINIC_VIEW)  ? 
                    <ClinicMaintenanceForm {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INSURANCE_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_INSURANCE_VIEW)  ? 
                    <InsurancePanelMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INSURANCE_MAINTENANCE_DETAILS + '/:insuranceid', element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_INSURANCE_VIEW)  ? 
                    <InsurancePanelMaintenanceDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.SUPPLIER_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_SUPPLIER_VIEW)  ? 
                    <SupplierMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.SUPPLIER_MAINTENANCE_DETAILS + '/:supplierid', element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_SUPPLIER_VIEW)  ? 
                    <SupplierMaintenanceDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.USER_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_USER_VIEW)  ? 
                    <UserMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.USER_MAINTENANCE_DETAILS + '/:userid', element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_USER_VIEW)  ? 
                    <UserMaintenanceForm {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.SERVICE_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_SERVICE_VIEW)  ? 
                    <ServiceMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.SERVICE_MAINTENANCE_DETAILS + '/:serviceid', element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_SERVICE_VIEW)  ? 
                    <ServiceMaintenanceDetails {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_SERVICE_CATEGORY_VIEW)  ? 
                    <ServiceCategoryMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                // { path: RouteEnum.PRICE_GROUP_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_PRICE_GROUP_VIEW) ?
                //     <PriceGroupMaintenance {...contentPageProps} /> : <ErrorPage />
                // ) },
                // { path: RouteEnum.PRICE_GROUP_MAINTENANCE_LIST, element: <PriceGroupMaintenance {...contentPageProps} /> },
                { path: RouteEnum.INVENTORY_MAINTENANCE_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_INVENTORY_VIEW)  ?
                    <InventoryMaintenance {...contentPageProps} /> : <ErrorPage />
                ) },
                { path: RouteEnum.INVENTORY_SETUP_LIST, element: (AccessHelper.hasAccess(MaintenanceAccessEnum.MAINTENANCE_INVENTORY_SETUP_VIEW)  ?
                    <InventorySetup {...contentPageProps} /> : <ErrorPage />
                ) },
                // { path: '/PriceMaintenance', element: (!GlobalFunctionUtils.accessChecker('/PriceMaintenance') ?
                //     <PriceMaintenance {...contentPageProps} /> : <ErrorPage />
                // ) },
                { path: '/PriceMaintenance', element: <PriceMaintenance {...contentPageProps} /> },
                { path: RouteEnum.PROFILE + '/:userid', element: <UserMaintenanceForm {...contentPageProps} /> },

                /* Print and Scan */
                { path: RouteEnum.PRINT_INVOICE_PAGE + '/:invoiceid', element: <PrintInvoicePage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_INVENTORY_PURCHASERETURN + '/:purchasereturnid', element: <PrintPurchaseReturnPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_INVENTORY_GOODSRETURN + '/:goodsreturnid', element: <PrintGoodsReturnPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PG_INVOICE_PAGE + '/:invoiceid', element: <PrintPostedGroupInvoicePage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_GROUP_INVOICE_PAGE + '/:chitid', element: <PrintGroupInvoicePage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PATIENT_INVOICE + '/:patientid', element: <PrintInvoicePage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_MEDICAL_CERTIFICATE + '/:certificateid', element: <PrintMedicalCertPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_OFFICIAL_RECEIPT + '/:invoiceid', element: <PrintOfficialReceiptPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PRESCRIPTION + '/:prescriptionid', element: <PrintPrescriptionPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PATIENT_PRESCRIPTION + '/:patientid', element: <PrintPrescriptionPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PRESCRIPTION_LABEL + '/:prescriptionid', element: <PrintPrescriptionLabelPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PATIENT_PRESCRIPTION_LABEL + '/:patientid', element: <PrintPrescriptionLabelPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_RECORD_LABEL + '/:patientid', element: <PrintRecordLabelPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_TIMESLIP + '/:certificateid', element: <PrintTimeSlipPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PO + '/:poid', element: <PrintPurchaseOrderPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_RECEIVE_ORDER + '/:receiveid', element: <PrintReceiveOrderPage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_CN + '/:cnid', element: <PrintCreditNotePage {...contentPageProps} /> },
                { path: RouteEnum.PRINT_RECEIPT + '/:invoiceid', element: <PrintReceipt {...contentPageProps} /> },
                { path: RouteEnum.PRINT_QUOTATION + '/:quotationid', element: <PrintQuotation {...contentPageProps} /> },
                { path: RouteEnum.PRINT_STOCK_TRANSFER + '/:stockid', element: <PrintStockTransfer {...contentPageProps} /> },
                { path: RouteEnum.PRINT_STOCK_RECEIVED + '/:stockid', element: <PrintStockReceived {...contentPageProps} /> },
                { path: RouteEnum.PRINT_ENDOFDAY_REPORT, element: <PrintEODReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_MEDICAL_REPORT, element: <PrintMedicalReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_QUEUE_REPORT, element: <PrintQueueReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_APPOINTMENTS_REPORT, element: <PrintAppointmentsReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_CLINICAL_SERVICES_INVENTORY_REPORT, element: <PrintClinincalServicesInventoryReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_AGINGS_REPORT, element: <PrintAgingsReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_PAYMENT_REPORT, element: <PrintPaymentsReport {...contentPageProps} /> },

                { path: RouteEnum.PRINT_PATIENT_REPORT, element: <PrintPatientsReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_INVOICE_REPORT, element: <PrintInvoiceReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_DISPENSE_REPORT, element: <PrintInventoryReport {...contentPageProps} /> },
                { path: RouteEnum.PRINT_STOCK_ADJUSTMENT_REPORT, element: <PrintStockAdjustmentReport {...contentPageProps} /> },


                { path: "*", element: <ErrorPage /> }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}

// export default PageLayout;
export default withCookies(PageLayout);
