import React, { useRef, useState, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import './PageLayout.scss';
import { retryLazyImport } from '../../../../store/SessionManager';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import {
    Box,
    Button,
} from '@mui/material/';

import {
    ArrowUpwardOutlinedIcon,
} from '../../../../utilities/Icons/Icons';
import GlobalFunctionsUtils from '../../../../utilities/GlobalFunctionUtils';
const NavigationHeaders = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/NavigationMenu/NavigationHeaders')));
const NavigationMenu = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/NavigationMenu/NavigationMenu')));
const Footer = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/Footer/Footer')));
const BreadcrumbsPage = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/BreadcrumbsPage/BreadcrumbsPage')));

const CustomDialog = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/CustomDialog/CustomDialog')));
const AdvanceFilterDrawer = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/AdvanceFilterDrawer/AdvanceFilterDrawer')));

const RootLayout = ({
    darkMode = false,
    setDarkMode = null,
    updateActiveTab = null,
    toggledMenu = false,
    setToggledMenu = null,
    loadingState = false,
    setLoadingState = null,
    DefaultGeneralSettings = null,
    errorOutlet
}) => {
    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    // Use states
    const [anchorDrawer, setAnchorDrawer] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState({
        title: null,
        content: null,
        clearFormData: null,
        footerContent: null,
    });
    const [pageDetails, setPageDetails] = useState({
        pageId: "",
        patientId: "",
        idType: "",
        pageTab: "",
        pageItemDetailsFromPage: "",

    });
    const [pos, setPos] = useState(false);


    //Variables
    const [cookies, setCookie, removeCookie] = useCookies(['navigatedPages']);
    const ref = useRef();

    let navigationMenuProps = {
        darkMode: darkMode,
        setDarkMode: setDarkMode,
        navigatedPages: cookies.navigatedPages,
        toggledMenu: toggledMenu,
    },
        navigationHeaderProps = {
            darkMode: darkMode,
            setDarkMode: setDarkMode,
            navigatedPages: cookies.navigatedPages,
            toggledMenu: toggledMenu,
            setToggledMenu: setToggledMenu,
        },
        contentPageProps = {
            darkMode: darkMode,
            loadingState: loadingState,
            setLoadingState: setLoadingState,
            navigatedPages: cookies.navigatedPages,
        },
        breadcrumbsProps = {
            darkMode: darkMode,
            navigatedPages: cookies.navigatedPages,
        };

    const theme = createTheme({
        palette: {
            mode: (darkMode == 'true' ? 'dark' : 'light'),
        },
    });

    //Event Handlers

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setAnchorDrawer({ ...anchorDrawer, [anchor]: open });
    };

    const checkSelectedPageIdAndTab = () => {
        var navPages = cookies.navigatedPages;

        if (navPages) {
            const activePage = navPages.filter(page => page.activeTab && page.isDetailsPage);

            if (activePage && activePage.length > 0) {
                if (cookies.pageDetails != null) {
                    setPageDetails(cookies.pageDetails);
                }
            } else {
                removeCookie("pageDetails");
            }
        }
    }

    navigationMenuProps = {
        ...navigationMenuProps,
        DefaultGeneralSettings: DefaultGeneralSettings,
    }

    navigationHeaderProps = {
        ...navigationHeaderProps,
        DefaultGeneralSettings: DefaultGeneralSettings,
    }

    contentPageProps = {
        ...contentPageProps,
        anchorDrawer: anchorDrawer,
        setAnchorDrawer: setAnchorDrawer,
        toggleDrawer: toggleDrawer,
        pageDetails: pageDetails,
    }

    breadcrumbsProps = {
        ...breadcrumbsProps,
    }

    const advanceFilterDrawerProps = {
        darkMode: darkMode,
        anchorDrawer: anchorDrawer,
        setAnchorDrawer: setAnchorDrawer,
        toggleDrawer: toggleDrawer,
    };

    const customDialogProps = {
        darkMode: darkMode,
        openDialog: openDialog,
        setOpenDialog: setOpenDialog,
        dialogData: dialogData,
        setDialogData: setDialogData,
    };

    const renderFilterDrawer = () => {
        return <AdvanceFilterDrawer {...advanceFilterDrawerProps} />
    }

    const renderCustomDialog = () => {
        return <CustomDialog {...customDialogProps} />
    }

    const handleTop = () => {
        ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        setPos(false);
    };

    const handleScroll = () => {
        if (ref.current.scrollTop > 50) {
            if (!pos) {
                setPos(true);
            }
        } else {
            if (pos) {
                setPos(false);
            }
        }
    };

    useEffect(() => {
        const temp = ref.current;
        if (temp != undefined) {
            temp.addEventListener("scroll", handleScroll);
            return () => temp.removeEventListener("scroll", handleScroll);
        }
    });

    return <div className="alproClinicMainContainer">
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <NavigationMenu {...navigationMenuProps} />
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <div className="pageComponentMainContainer">
                        <NavigationHeaders {...navigationHeaderProps} />
                        <BreadcrumbsPage {...breadcrumbsProps} />
                        {/* {renderPageComponent()} */}
                        <div className="pageComponentContainer" id="pageComponentContainer" ref={ref}>
                            <div className="pageContent">
                                {errorOutlet ? errorOutlet : <Outlet />}
                            </div>
                        </div>
                        {/* {renderFilterDrawer()}
                        {renderCustomDialog()} */}
                        <Footer />

                        {/*<IconButton
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          display: pos ? "block" : "none"
        }}
        onClick={handleTop}
      >
        Up
      </IconButton>*/}

                        <Button
                            key={GlobalFunctionsUtils.getRanHex(24)}
                            style={{
                                display: pos ? "block" : "none"
                            }}
                            className="scrollToTopBtn"
                            variant="outlined"
                            size="small"
                            onClick={handleTop}>
                            <ArrowUpwardOutlinedIcon className="scrollToTopBtnIcon notMUIIcon" />
                        </Button>
                    </div>
                </Box>
            </Box>
        </ThemeProvider>
    </div>
}

export default RootLayout;