import {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
} from "./apiClient";

//#region 
const CreditNoteUrl = 'creditNotes';
//#endregion 

const CreditNoteService = {
    getAllCreditNotes: async (queryString) => {
        var _url = CreditNoteUrl + queryString;
        var res = await getRequest(_url);

        return res.data;
    },
    getCreditNoteById: async (id) => {
        var _url = CreditNoteUrl + "/" + id;
        var res = await getRequest(_url);

        return res.data;
    },
    createCreditNote: async (param) => {
        var res = await postRequest(CreditNoteUrl, param);

        return res;
    },
    
    putCreditNote: async (param, id) => {
        var _url = CreditNoteUrl + "/" + id;
        var res = await putRequest(_url, param);

        return res;
    },
    deleteCreditNote: async (id) => {
        var _url = CreditNoteUrl + "/" + id;
        var res = await deleteRequest(_url);

        return res;
    },
    putPayment: async (param, id) => {
        var _url = CreditNoteUrl + "/" + id + "/pay";
        var res = await putRequest(_url, param);

        return res;
    },
    putRefund: async (param, id) => {
        var _url = CreditNoteUrl + "/" + id + "/refund";
        var res = await putRequest(_url, param);

        return res;
    },
}

export default CreditNoteService
