export const RoleEnum = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    DOCTOR: 3,
    PATIENT: 4,
    NURSE: 5,
    PHARMACIST: 6,
    ACCOUNTANT: 7,
    RECEPTIONIST: 8,
    DIRECTOR: 9,
    LEAD_ADMIN: 18,
    DEV1: 19,
    CLINIC_ADMIN: 26,
    TESTER: 28
}