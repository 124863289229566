import React from 'react';
import {
    useLocation,
} from 'react-router-dom';
import CryptoJS from "crypto-js";

import { MenuItems } from '../components/CommonComponents/Layout/NavigationMenu/MenuItems.jsx';
import { RouteEnum } from './Enums/RouteEnums.jsx';

import InvoiceService from '../services/invoiceservice';
import CreditNoteService from '../services/creditnoteservice';
import { differenceInCalendarMonths, differenceInCalendarYears, differenceInDays, differenceInSeconds, monthsToYears, toDate } from 'date-fns';
import moment from "moment";
import LocalStorageHelper from './LocalStorageHelper';
import { QueueStatusEnum } from './Enums/StatusEnum.jsx';
import { PaymentTypeEnum } from './Enums/PaymentTypeEnum.jsx';

const secretPass = "XkhZG4fW2t2W";
const userData = LocalStorageHelper.getUserData();

const GlobalFunctionsUtils = {
    encodeID: function (str) {
        return str.replaceAll("/", "%2F")
    },
    decodeID: function (str) {
        return str.replaceAll("%2F", "/")
    },
    getRequiredMsg: function () {
        return 'This is required'
    },
    getRequiredInputSnackbar: function () {
        return 'Please fill up required fields'
    },
    getRanHex: function (size) {
        return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
    },
    hasChildren: function (item) {
        const { submenu: children } = item;

        if (children === undefined) {
            return false;
        }

        if (children.constructor !== Array) {
            return false;
        }

        if (children.length === 0) {
            return false;
        }

        return true;
    },
    getTabName: function () {
        const splitSelectedPagePath = window.location.pathname.split("/");
        const selectedPagePath = (splitSelectedPagePath[1] == null || splitSelectedPagePath[1] == "" ?
            "Dashboard" : (splitSelectedPagePath[1] == "MedicalRecords" ?
                "Medical Records" : splitSelectedPagePath[1]
            )
        );

        switch (splitSelectedPagePath[1]) {
            case "MedicalRecords":
                return "Medical Records";
                break;

            case "MedicalReports":
                return "Medical Reports";
                break;

            case "InvoiceReports":
                return "Invoice Reports";
                break;

            case "RoleAndAccessibility":
                return "Role & Accessibility";
                break;

            case "ClinicMaintenance":
                return "Clinic Maintenance";
                break;

            case "UserMaintenance":
                return "User Maintenance";
                break;

            case "DrugMaintenance":
                return "Drug Maintenance";
                break;

            case "ServiceMaintenance":
                return "Clinic Maintenance";
                break;

            default:
                return "Dashboard";
                break;
        }
    },

    getSelectedPagePath: function () {
        const selectedPagePath = window.location.pathname;

        return selectedPagePath;
    },
    getSelectedPageParentPath: function (navigatedPages = null) {
        const parentDetails = [];

        const navPages = (navigatedPages != null ? navigatedPages.filter(navPage => navPage.activeTab == true) : []);
        const parentName = (navPages.length > 0 ? navPages[0].parentName : null);
        const getParent = MenuItems.filter(filtemenuItem => filtemenuItem.tabName.includes(parentName));

        if (getParent.length > 0) {
            parentDetails.push(getParent[0]);
        }

        return parentDetails;
    },

    getSelectedMoreOptItem: function () {
        const SelectedMoreOptItem = window.location.pathname;

        switch (SelectedMoreOptItem) {
            case RouteEnum.MEDICAL_CERTIFICATE_REPORT:
            case RouteEnum.INVOICE_REPORT:
                return "/Reports";
                break;
            case RouteEnum.ROLE_MAINTENANCE_LIST:
            case RouteEnum.CLINIC_MAINTENANCE_LIST:
            case RouteEnum.USER_MAINTENANCE_LIST:
            case "/DrugMaintenance":
            case RouteEnum.SERVICE_MAINTENANCE_LIST:
                return "/Maintenance";
                break;

            default:
                return SelectedMoreOptItem;
                break;
        }
    },
    getSelectedSubMenuItem: function () {
        const SelectedMoreOptItem = window.location.pathname;

        switch (SelectedMoreOptItem) {
            case RouteEnum.MEDICAL_CERTIFICATE_REPORT:
            case RouteEnum.MEDICAL_CERTIFICATE_REPORT:
            case RouteEnum.ROLE_MAINTENANCE_LIST:
            case RouteEnum.CLINIC_MAINTENANCE_LIST:
            case RouteEnum.USER_MAINTENANCE_LIST:
            case "/DrugMaintenance":
            case RouteEnum.SERVICE_MAINTENANCE_LIST:
                return SelectedMoreOptItem;
                break;

            default:
                return SelectedMoreOptItem;
                break;
        }
    },
    toggleFullScreen: function (elem) {
        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            if (elem.requestFullScreen) {
                elem.requestFullScreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }

        return true;
    },
    getCurrentDate: function () {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${date}/${month}/${year}`
    },
    getCurrentTime: function () {
        const locale = 'en';
        let newDate = new Date();
        return newDate.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
    },
    formatAgeWithMonths: function (age, birthday) {
        var bday = birthday;
        if (birthday == "0001-01-01T00:00:00" || birthday == "1-01-01") {
            bday = "2001-01-01T00:00:00"
        }
        var ageStr = "0";
        var currentAge = parseInt(age) || 0
        if (currentAge != null) {
            if (currentAge < 1) {
                if (bday) {
                    const monthOld = differenceInCalendarMonths(Date.now(), new Date(bday))
                    if (!isNaN(monthOld)) {
                        if (monthOld < 12)
                            ageStr = `${monthOld} months old`
                        else {
                            const yrOld = monthsToYears(monthOld)
                            ageStr = `${yrOld} years old`
                        }
                    }
                }
            }
            else
                ageStr = `${currentAge} years old`
                
        }
        return ageStr;
    },
    calculateAge: function (birthday) {
        if (birthday) {
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch

            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
        return 0;
    },
    withLeadingZeros: function (num, totalLength) {
        return String(num).padStart(totalLength, '0');
    },
    getFormattedDate: function (d, format = 'dmy', separator = '/', isFullName = false) {

        let newDate = d ? new Date(d) : new Date();
        let date = this.withLeadingZeros(newDate.getDate(), 2);
        let month = this.withLeadingZeros(newDate.getMonth() + 1, 2);
        let year = newDate.getFullYear();
        let hours = this.withLeadingZeros(newDate.getHours(), 2);
        let hours2 = this.withLeadingZeros((newDate.getHours() > 12 ? (newDate.getHours() - 12)
            : newDate.getHours() == 0 ? (newDate.getHours() + 12) : newDate.getHours()), 2);
        let amPm = (newDate.getHours() >= 12 ? "PM" : "AM");
        let minutes = this.withLeadingZeros(newDate.getMinutes(), 2);
        let seconds = this.withLeadingZeros(newDate.getSeconds(), 2);

        switch (format) {
            case "dmy":
                return `${date}${separator}${month}${separator}${year}`
                break;

            case "dMy":
                return `${date} ${this.getMonthName(parseInt(month), isFullName)}${separator} ${year}`
                break;

            case "My":
                return `${this.getMonthName(parseInt(month), isFullName)}${separator} ${year}`
                break;    

            case "ymd":
                return `${year}${separator}${month}${separator}${date}`
                break;

            case "ymdhms":
                return `${year}${separator}${month}${separator}${date}T${hours}:${minutes}:${seconds}`
                break;

            case "dmyhma":
                return `${date}${separator}${month}${separator}${year} ${hours2}:${minutes} ${amPm}`
                break;

            case "hm":
                return `${hours}:${minutes}`
                break;

            case "hma":
                return `${hours2}:${minutes} ${amPm}`
                break;

            case "hms":
                return `${hours}:${minutes}:${seconds}`
                break;

            case "date":
                return `${date}`
                break;

            case "month":
                return `${month}`
                break;

            case "year":
                return `${year}`
                break;

            case "hours":
                return `${hours}`
                break;

            case "minutes":
                return `${minutes}`
                break;

            case "seconds":
                return `${seconds}`
                break;

            case "MonthYear":
                return `${this.getMonthName(parseInt(month), isFullName)} ${year}`
                break;

            case "Md":
                return `${this.getMonthName(parseInt(month), isFullName)} ${date}`
                break;

            case "Mdy":
                return `${this.getMonthName(parseInt(month), isFullName)} ${date}${separator} ${year}`
                break;

            case "dy":
                return `${date}${separator} ${year}`
                break;

            default:
                return `${date}${separator}${month}${separator}${year}`
                break;
        }
    },

    getFormattedDateRange: function (startDate, endDate) {
        return (startDate && endDate ?
            (this.getFormattedDate(startDate, "dmy", "/") + " - " + this.getFormattedDate(endDate, "dmy", "/"))
            : this.getCurrentDate())
    },
    calculateDayDifference: function (startDate, endDate) {
        //'differenceInDays' calculate based on 24 hour as 1 day
        //if start is Day 1 - 7.30pm, end is Day 2 - 10.30am, total is 15 hours result in 0 days, suppose is 1 day
        //parameter receive as: yyyy-mm-ddTHH:mm:ss (eg -> 2024-05-28T19:23:58)
        var result = differenceInDays(new Date(endDate), new Date(startDate))

        //temp fix: calculate based on days
        let final = 0;
        if (startDate !== null && startDate !== undefined && endDate !== null && endDate !== undefined) {
            var _start = startDate.split("T");
            var _end = endDate.split("T");
            if (_start.length > 0 && _end.length > 0) {
                var _startdate = _start[0].split("-");
                var _enddate = _end[0].split("-");
                let _startmth = +_startdate[1]; //get month convert to int
                let _endmth = +_enddate[1]; //get month convert to int

                const calc = new moment();
                let _startday = +_startdate[2]; //get day convert to int
                let _endday = +_enddate[2]; //get day convert to int

                if (_startmth === _endmth) {
                    final = _endday - _startday;
                } else if (_startmth < _endmth) {
                    calc.set('month', _startmth - 1); // index start with 0
                    var totaldays = calc.daysInMonth();
                    var _diff = totaldays - _startday;
                    final = _diff + _endday;
                }
            }
        } else {
            //is a time-slip
        }
        return final + 1;
    },
    getMonthName: function (month, isFullName = false) {
        switch (month) {
            case 1:
                return (isFullName ? "January" : "Jan");
                break;

            case 2:
                return (isFullName ? "February" : "Feb");
                break;

            case 3:
                return (isFullName ? "March" : "Mar");
                break;

            case 4:
                return (isFullName ? "April" : "Apr");
                break;

            case 5:
                return (isFullName ? "May" : "May");
                break;

            case 6:
                return (isFullName ? "June" : "Jun");
                break;

            case 7:
                return (isFullName ? "July" : "Jul");
                break;

            case 8:
                return (isFullName ? "August" : "Aug");
                break;

            case 9:
                return (isFullName ? "September" : "Sep");
                break;

            case 10:
                return (isFullName ? "October" : "Oct");
                break;

            case 11:
                return (isFullName ? "November" : "Nov");
                break;

            case 12:
                return (isFullName ? "December" : "Dec");
                break;

            default:
                return "";
                break;
        }
    },
    encryptText: function (textString) {
        return CryptoJS.AES.encrypt(
            JSON.stringify(textString),
            secretPass
        ).toString();
    },
    decryptText: function (textString) {
        const bytes = CryptoJS.AES.decrypt(textString, secretPass);

        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    },
    splitAccessItems: function (textString) {
        let accessItems = textString.split("");
        let aItems = [];

        if (accessItems.length > 0) {
            accessItems.forEach((item, index) => {
                aItems.push(parseInt(item) > 0 ? true : false);
            });
        }

        return aItems;
    },
    combineAccessItems: function (arrayItems) {
        let aItemsString = "";

        if (arrayItems.length > 0) {
            arrayItems.forEach((item, index) => {
                aItemsString += (item ? "1" : "0");
            });
        }

        return aItemsString;
    },
    percentToDecimal: function (percentStr) {
        return parseFloat(percentStr) / 100;
    },

    sortAscending: function (object, fieldName) {
        return object.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : ((b[fieldName] > a[fieldName]) ? -1 : 0))
    },
    sortDesscending: function (object, fieldName) {
        return object.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0))
    },
    getAllItemPageSize: function () {
        return 2000;
    },
    getFileExtension: function (filename) {
        return filename.split('.').pop().toLowerCase();
    },
    getLastDigit: function (decimalNum) {
        return (Number.isInteger(decimalNum) ?
            decimalNum % 10 : decimalNum.toString().slice(-1)
        );
    },
    capitalizeFirstLetter: function (str) {
        if (str == '' || str == undefined || str == null || str == []) return;
        if (str.length == 1)
            return str.toUpperCase()
        let s = str.charAt(0).toUpperCase() + str.slice(1) 
        return s
    }, 
    capitalizeFirstAndLowerCaseOther: function (str) {
        if (str == '' || str == undefined || str == null || str == []) return;
        if (str.length == 1)
            return str.toUpperCase()
        let s = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        return s
    }, 
    convertDateForJsonStringify: function (dateStr) {
        var sd = new Date(dateStr);
        // let hoursDiff = sd.getHours() - sd.getTimezoneOffset() / 60;
        // let minutesDiff = (sd.getHours() - sd.getTimezoneOffset()) % 60;
        // sd.setHours(hoursDiff);
        // sd.setMinutes(minutesDiff);

        return new Date(sd.getTime() - (sd.getTimezoneOffset() * 60000));
    },
    generateCreditNote: async function (invoiceId) {
        let _success = false;
        let _msg = "Nothing to generate as Credit Note";
        let _creditNoteId = 0;

        const getInvoiceData = await InvoiceService.getInvoiceById(invoiceId);

        if (getInvoiceData != null && getInvoiceData.success) {
            let invoiceData = getInvoiceData.data;
            let _lineItems = [];
            let creditNoteData = {
                creditNoteType: invoiceData.invoiceType,
                adjustAmount: invoiceData.adjustAmount,
                creditNoteDate: new Date(),
                discountAmount: invoiceData.discountAmount,
                discountType: invoiceData.discountType,
                discountValue: invoiceData.discountValue,
                // discountedAmount: "0.00",
                grandTotal: invoiceData.grandTotal,
                gstPayable: invoiceData.gstPayable,
                gstType: invoiceData.gstType,
                gstValue: invoiceData.gstValue,
                id: 0,
                insuranceId: (invoiceData.insuranceId != null && invoiceData.insuranceId != "" ? invoiceData.insuranceId : 0),
                invoiceId: invoiceData.id,
                invoiceTransNo: invoiceData.transNo,
                lineItems: [],
                patientId: invoiceData.patientId,
                referenceNo: (invoiceData && invoiceData.patientInfo && invoiceData.patientInfo.pno && invoiceData.patientInfo.pno != null && invoiceData.patientInfo.pno != "" ?
                    invoiceData.patientInfo.pno : ""
                ),
                // remainingCredit: "0.00",
                remarks: invoiceData.remarks,
                totalExcludingGST: invoiceData.totalExcludingGST,
                totalSales: invoiceData.totalSales,
                validityDate: new Date(),
                remainingCredit: 0,
            };

            if (invoiceData.lineItems.length > 0) {
                invoiceData.lineItems.forEach((item, index) => {
                    if (item.type == 1) {
                        _lineItems.push({
                            itemId: item.itemId,
                            type: 1,
                            qty: item.qty,
                            cost: (item.cost && item.cost != null && item.cost != "" ? item.cost : 0),
                            price: item.price,
                            subTotal: item.subTotal,
                            lineTotal: item.lineTotal,
                            discountType: item.discountType,
                            discountValue: item.discountValue,
                            discountAmount: item.discountAmount,
                            remarks: item.remarks,
                            instructions: item.instructions,
                            precautions: item.precautions,
                            reference: item.reference,
                            dosage: item.dosage,
                            consume: item.consume,
                            name: (item && item.medication && item.medication.itemName && item.medication.itemName != null && item.medication.itemName != "" ?
                                item.medication.itemName : ""
                            ),

                            consumeId: (item.consumeId && item.consumeId != null && item.consumeId != "" ? item.consumeId : 0),
                            instructionId: (item.instructionId && item.instructionId != null && item.instructionId != "" ? item.instructionId : 0),
                            precautionId: (item.precautionId && item.precautionId != null && item.precautionId != "" ? item.precautionId : 0),

                            dispenseUOMId: (item.dispenseUOMId && item.dispenseUOMId != null && item.dispenseUOMId != "" ? item.dispenseUOMId : 0),
                            dispenseUOMName: (item.dispenseUOMName && item.dispenseUOMName != null && item.dispenseUOMName != "" ? item.dispenseUOMName : ""),
                            dosageUOMId: (item.dosageUOMId && item.dosageUOMId != null && item.dosageUOMId != "" ? item.dosageUOMId : 0),
                            dosageUOMName: (item.dosageUOMName && item.dosageUOMName != null && item.dosageUOMName != "" ? item.dosageUOMName : ""),

                            dispenseUOCId: (item.dispenseUOCId && item.dispenseUOCId != null && item.dispenseUOCId != "" ? item.dispenseUOCId : 0),
                            dispenseUOMQty: (item.dispenseUOMQty && item.dispenseUOMQty != null && item.dispenseUOMQty != "" ? item.dispenseUOMQty : 0),
                            dispenseUOMVal: item.qty / (item.dispenseUOMQty && item.dispenseUOMQty != null && item.dispenseUOMQty != "" ? item.dispenseUOMQty : 0),
                        });
                    }
                });
            }

            creditNoteData.lineItems = _lineItems;

            if (creditNoteData.lineItems.length > 0) {
                let newTotalExcludingGST = 0;
                let newDiscountedAmount = 0;
                let _gstValue = (isNaN(parseFloat(creditNoteData.gstValue)) ?
                    0 : parseFloat(creditNoteData.gstValue)
                );
                let newGstPayable = 0;
                let newTotalSales = 0;
                let newAdjustAmount = 0;
                let newGrandTotal = 0;
                let newRemainingCredit = creditNoteData.remainingCredit;
                let lastDigit = 0;
                let adjuster = 0;
                let _discountValue = (isNaN(parseFloat(creditNoteData.discountValue)) ?
                    0 : (creditNoteData.discountType.toString() == "1" && parseFloat(creditNoteData.discountValue) > 100 ?
                        100 : parseFloat(creditNoteData.discountValue)
                    )
                );

                if (creditNoteData && creditNoteData.lineItems.length > 0) {
                    creditNoteData.lineItems.forEach(item => {
                        newTotalExcludingGST += item.lineTotal;
                    });
                }

                newTotalExcludingGST = newTotalExcludingGST.toFixed(2);

                let newDiscountAmount = (creditNoteData.discountType.toString() == "2" ?
                    _discountValue : (parseFloat(newTotalExcludingGST) * this.percentToDecimal(_discountValue))
                );

                newDiscountedAmount = parseFloat(parseFloat(newTotalExcludingGST) - newDiscountAmount).toFixed(2);
                newGstPayable = parseFloat(parseFloat(newDiscountedAmount) * this.percentToDecimal(_gstValue)).toFixed(2);
                newTotalSales = parseFloat(parseFloat(newDiscountedAmount) + parseFloat(newGstPayable)).toFixed(2);
                lastDigit = this.getLastDigit(newTotalSales);

                if ((lastDigit <= 2 && lastDigit >= 1) || (lastDigit <= 7 && lastDigit >= 6)) {
                    adjuster = (lastDigit == 7 ?
                        2 : (lastDigit == 6 ?
                            1 : lastDigit
                        )
                    );
                    newAdjustAmount = (adjuster / 100) * -1;
                }

                if ((lastDigit <= 4 && lastDigit >= 3) || (lastDigit <= 9 && lastDigit >= 8)) {
                    adjuster = (lastDigit == 8 || lastDigit == 3 ?
                        2 : (lastDigit == 9 || lastDigit == 4 ?
                            1 : 0
                        )
                    );
                    newAdjustAmount = (adjuster / 100);
                }

                newAdjustAmount = parseFloat(newAdjustAmount).toFixed(2);

                newGrandTotal = parseFloat(parseFloat(newTotalSales) + parseFloat(newAdjustAmount)).toFixed(2);
                newRemainingCredit = parseFloat(newRemainingCredit).toFixed(2);


                creditNoteData.discountValue = _discountValue;
                creditNoteData.totalExcludingGST = newTotalExcludingGST;
                creditNoteData.discountAmount = newDiscountAmount;
                creditNoteData.discountedAmount = newDiscountedAmount;
                creditNoteData.gstPayable = newGstPayable;
                creditNoteData.totalSales = newTotalSales;
                creditNoteData.adjustAmount = newAdjustAmount;
                creditNoteData.grandTotal = newGrandTotal;
                creditNoteData.remainingCredit = newRemainingCredit;

                var createResponse = await CreditNoteService.createCreditNote(JSON.stringify(creditNoteData));

                _success = (createResponse.isSuccess ? true : false);
                _msg = (createResponse.errorMessage != null ? createResponse.errorMessage : createResponse.data.message);
                _creditNoteId = (_success ? createResponse.data.data.id : 0);
            } else {
                _msg = "Refunds for services are not permitted"
            }
        }

        return { success: _success, msg: _msg, creditNoteId: _creditNoteId };
    },
    getMenuPath: function (routeParent) {
        let menuPath = null;
        let exitLoop = false;

        if(routeParent != null && routeParent != "") {
            let splitPath = routeParent.split('/');

            if(splitPath.length > 0 && splitPath[1] && splitPath[1] != null && splitPath[1] != "" && MenuItems.length > 0) {
                let _currentPage = splitPath[1];

                MenuItems.forEach((item, index) => {
                    if(!exitLoop) {
                        if(item.hasSubMenu) {
                            menuPath = this.getPath(item.subMenuItems, _currentPage);
                            
                            if(menuPath != null) {
                                exitLoop = true;
                            }
                        } else if(item.tabName == _currentPage) {
                            menuPath = {
                                tabName: item.name,
                                parentName:item.parentName,
                            };

                            exitLoop = true;
                        }
                    }
                });
            }
        }

        return menuPath;
    },
    getPath: function (menuItems, pathName) {
        let menuPath = null;
        let exitLoop = false;

        menuItems.forEach((item, index) => {
            if(!exitLoop) {
                if(item.hasSubMenu) {
                    menuPath = this.getPath(item.subMenuItems, pathName);

                    if(menuPath != null) {
                        exitLoop = true;
                    }
                } else if(item.tabName == pathName) {
                    menuPath = {
                        tabName: item.name,
                        parentName:item.parentName,
                    };

                    exitLoop = true;
                }
            }
        });

        return menuPath;
    },
    removeQueryOnRoutePath: function () {
        let routePath = "";

        if(window.location.pathname != null && window.location.pathname != "") {
            let splitPath = window.location.pathname.split('/');
            
            if(splitPath.length > 0 && splitPath[1] && splitPath[1] != null && splitPath[1] != "" && MenuItems.length > 0) {
                routePath =  "/" + splitPath[1];
            }
        }
        
        return routePath;
    },
    queueWaitingPeriod: function (timeIn, timeOut, status, isDetailed = false) {
        let waitingPeriod = "--";
        let wtngPrdDiff = (timeIn && timeIn != '' && timeOut && timeOut != '' ? differenceInSeconds(new Date(timeOut), new Date(timeIn)) : 0);
        if (status == QueueStatusEnum.COMPLETED) {
            if (wtngPrdDiff > 0 && !isNaN(wtngPrdDiff)) {
                let hrs = Math.floor(wtngPrdDiff / 3600);
                let mins = Math.floor((wtngPrdDiff % 3600) / 60)
                let secs = Math.floor(wtngPrdDiff % 60)
                if (isDetailed)
                    waitingPeriod = hrs > 0 ? `${hrs} Hr ${mins} Min` : mins > 0 ? `${mins} Min` : `${secs} Sec`;
                else {
                    const formattedTime = `${hrs.toLocaleString("en-US", {
                        minimumIntegerDigits: 2
                    })}:${mins.toLocaleString("en-US", {
                        minimumIntegerDigits: 2
                    })}:${secs.toLocaleString("en-US", {
                        minimumIntegerDigits: 2
                    })}`;
                    waitingPeriod = formattedTime
                }
            } else {
                waitingPeriod = "--"//"00:00:00";
            }
        } else
            waitingPeriod = "--";
        
        return waitingPeriod;
    },
    accessChecker: function(route) {
        let hadAccess = true;

        switch(route) {
            /* Maintenance Menu */
            case RouteEnum.GENERAL_SETTINGS:
            case RouteEnum.ROLE_MAINTENANCE_LIST:
            case RouteEnum.ROLE_MAINTENANCE_DETAILS:
            case RouteEnum.CLINIC_MAINTENANCE_LIST:
            case RouteEnum.CLINIC_MAINTENANCE_DETAILS:
            case RouteEnum.INSURANCE_MAINTENANCE_LIST:
            case RouteEnum.INSURANCE_MAINTENANCE_DETAILS:
            case RouteEnum.SUPPLIER_MAINTENANCE_DETAILS:
            case RouteEnum.USER_MAINTENANCE_LIST:
            case RouteEnum.USER_MAINTENANCE_DETAILS:
            case RouteEnum.SERVICE_MAINTENANCE_LIST:
            case RouteEnum.SERVICE_MAINTENANCE_DETAILS:
            case RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST:
            case RouteEnum.PRICE_GROUP_MAINTENANCE_LIST:
            case RouteEnum.INVENTORY_MAINTENANCE_LIST:
            case RouteEnum.INVENTORY_SETUP_LIST:
            case '/PriceMaintenance':
                hadAccess = (userData && userData.roleId && userData.roleId != null && userData.roleId != "" && userData.roleId != 1 && userData.roleId != 18 ? false : true);
            break;
        }

        return hadAccess;
    },
    exportFileGenerator: function (module, fromDate, endDate) {

        let userData = LocalStorageHelper.getUserData();
        var fileName = (userData.clinicName ? userData.clinicName : "Alpro Clinic") + "_" + module;
        if (fromDate && endDate) {
            fileName = fileName + " " + this.getFormattedDate(fromDate, "dmy", "-") + " to " + this.getFormattedDate(endDate, "dmy", "-")
        }
        return fileName + ".xlsx"
    },
    exportExcelFile: function (file, fileName) {
        //let binaryString = window.atob(file);
        //var binaryLen = binaryString.length;
        //var bytes = new Uint8Array(binaryLen);
        //for (var i = 0; i < binaryLen; i++) {
        //    var ascii = binaryString.charCodeAt(i);
        //    bytes[i] = ascii;
        //}
        var blobURL = URL.createObjectURL(new Blob([file]), { type: "application/vnd.ms-excel" });
        const link = document.createElement('a');
        link.href = blobURL;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    },
    getWeekDateRange: function (dateString) {
        var curr1 = new Date(dateString);
        var curr2 = new Date(dateString);

        var first = curr1.getDate() - curr1.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        var firstday = new Date(curr1.setDate(first)).toUTCString();
        var lastday = new Date(curr2.setDate(last)).toUTCString();

        return [new Date(firstday), new Date(lastday)];
    },

    getWeekDayName: function (day, isFullName) {
        let weekDayName = "";

        switch(day) {
            case 0:
                weekDayName = (isFullName ? "Sunday" : "Sun");
            break;
            case 1:
                weekDayName = (isFullName ? "Monday" : "Mon");
            break;
            case 2:
                weekDayName = (isFullName ? "Tuesday" : "Tue");
            break;
            case 3:
                weekDayName = (isFullName ? "Wednesday" : "Wed");
            break;
            case 4:
                weekDayName = (isFullName ? "Thursday" : "Thu");
            break;
            case 5:
                weekDayName = (isFullName ? "Friday" : "Fri");
            break;
            case 6:
                weekDayName = (isFullName ? "Saturday" : "Sat");
            break;
            
        }

        return weekDayName;
    },
    getPaymentDescription: function (paymentEnum) {
        switch (paymentEnum) {
            case PaymentTypeEnum.CASH: return "Cash";
            case PaymentTypeEnum.VISA_CARD: return  "Visa Card";
            case PaymentTypeEnum.MY_DEBIT: return "MyDebit";
            case PaymentTypeEnum.BANK_TRANSFER: return "Bank Transfer";
            case PaymentTypeEnum.CHEQUE: return "Cheque";
            case PaymentTypeEnum.OTHER: return "Other";
            case PaymentTypeEnum.MASTER_CARD: return "Master Card";
            case PaymentTypeEnum.AMERICAN_EXPRESS: return "American Express";
        }
        return 'Cash'
    },
    getGenderbyNRIC: function (nric) {
        let gender = "";
        let isEven = nric % 2;

        if(isEven == 0){
            gender = '2';
        }  
        else{
            gender = '1';
        }

        return gender
    },
    getDOBbyNRIC: function (nric) {
        let _dobYear = '19' + nric.substring(0,2);
        let _dobMonth = nric.substring(2,4);
        let _dobDay = nric.substring(4,6);
        let _dob = _dobYear + '/' + _dobMonth + '/' + _dobDay;
        let _birthDate = new Date(_dob); 
        let dob = _birthDate;

        return dob
    },
    formatcountryCode: function (countryCode) {
        return (countryCode && countryCode != "" ? 
            (countryCode == "Malaysia (+60)" ?
                "+60" : (countryCode == "Singapore (+65)" ?
                    "+65" : (countryCode.includes("+") ?
                        countryCode : "+" + countryCode
                    )
                )
            ) : ""
        );
    },
}

export default GlobalFunctionsUtils;