import Box from '@mui/material/Box';

import loaderGIF from '../../../assets/loader.gif';

import {
    CircularProgressIcon,
}
from '../../../utilities/Icons/Icons';

import './Loader.scss';

/* eslint no-restricted-globals: "off" */
const Loader = () => {
    // const errorMessage = (message ?
    //     <Box
    //         sx={{ typography: 'subtitle2' }}
    //         className="errorhandlerContainer" >
    //         {message}
    //     </Box> : <div className="errorhandlerContainer"></div>
    // );

    return (
        <div className="loaderContainer">
            {/*<img src={loaderGIF} />*/}
            <CircularProgressIcon className="loaderIcon" />
        </div>
    );
}

export default Loader;