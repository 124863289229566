/*
src/reducers/rootReducer.js
*/

import { combineReducers } from 'redux';
// import settingsReducer from './settingsReducer';
// import authenticationReducer from './authenticationReducer';
// import queueReducer from './queueReducer';

// const appReducer = combineReducers({
//    // settingsReducer,
//    // authenticationReducer,
//    // queueReducer,
// });

const rootReducer = (state, action) => {
   // Clear all data in redux store to initial.
   if (action.type === 'LOGOUT_USER')
      state = undefined;

   // return appReducer(state, action);
};

export default rootReducer;