import { RouteEnum } from "../Enums/RouteEnums";

const RouteHelper = {

    getRouteDescription: function (routeEnum) {
        switch (routeEnum) {
            //Dashboard
            case RouteEnum.DASHBOARD: return 'Dashboard';

            //Queue
            case RouteEnum.QUEUE: return 'Queue';
            case RouteEnum.TODAY_QUEUE_LIST: return 'Today`s Queue';
            case RouteEnum.PAST_QUEUE_LIST: return 'Past Queue';

            //Patients
            case RouteEnum.PATIENT: return 'Patients';
            case RouteEnum.PATIENT_LIST: return 'Patients List';
            case RouteEnum.NEW_REGISTERED_PATIENT_LIST: return 'New Registered Patients';
            case RouteEnum.NEW_PATIENT: return 'Register New Patient';
            case RouteEnum.PATIENT_DETAILS:
            case RouteEnum.PATIENT_DETAILS_DRUG_ALLERGY:
            case RouteEnum.PATIENT_DETAILS_INFO:
            case RouteEnum.PATIENT_DETAILS_APPOINTMENT:
            case RouteEnum.PATIENT_DETAILS_DISPENSING:
            case RouteEnum.PATIENT_DETAILS_PRESCRIPTION:
            case RouteEnum.PATIENT_DETAILS_INVOICE:
                return 'Patient Details';

            //Appointments
            case RouteEnum.APPOINTMENT: return 'Appointment';
            case RouteEnum.APPOINTMENT_LIST: return 'Appointment List';
            case RouteEnum.APPOINTMENT_CALENDAR: return 'Appointment Calendar';

            //Prescription
            case RouteEnum.PRESCRIPTION_LIST: return 'Prescription';
            case RouteEnum.PRESCRIPTION_DETAILS: return 'Prescription Details';

            //Invoices
            case RouteEnum.INVOICE_LIST: return 'Invoices';
            case RouteEnum.INVOICE_DETAILS: return 'Invoice Details';
            case RouteEnum.CREDIT_NOTE_LIST: return 'Credit Note';
            case RouteEnum.CREDIT_NOTE_DETAILS: return 'Credit Invoice Details';
            case RouteEnum.INVOICE_DETAILED_SUMMARY: return 'Invoices Detailed Summary';
            case RouteEnum.GROUP_INVOICE_LIST: return 'Group Invoices';
            case RouteEnum.POSTED_GROUP_INVOICE_LIST: return 'Posted Group Invoices';
            case RouteEnum.POSTED_GROUP_INVOICE_DETAILS: return 'Posted Group Invoices Details';
            case RouteEnum.QOUTATION_LIST: return 'Quotation';
            case RouteEnum.QUOTATION_DETAILS: return 'Quotation Details';
            case RouteEnum.MAKE_PAYMENT_FORM: return 'Payment Form';
            case RouteEnum.REFUND_PAYMENT_FORM: return 'Refund Payment Form';

            //Inventory
            case RouteEnum.INVENTORY_LIST: return 'Inventory';
            case RouteEnum.INVENTORY_DETAILS:
            case RouteEnum.INVENTORY_MAINTENANCE_DETAILS:
                return 'Inventory Details';
            case RouteEnum.VENDOR: return 'Vendor';
            case RouteEnum.RECEIVABLE_ORDER_LIST: return 'Goods Received';
            case RouteEnum.RECEIVABLE_ORDER_DETAILS: return 'Goods Received Details';
            case RouteEnum.PURCHASE_ORDER_LIST: return 'Purchase Order';
            case RouteEnum.PURCHASE_ORDER_DETAILS: return 'Purchase Order Details';
            case RouteEnum.PURCHASE_RETURN_LIST: return 'Purchase Return';
            case RouteEnum.PURCHASE_RETURN_DETAILS: return 'Purchase Return Details';
            case RouteEnum.BRANCH: return 'Branch';
            case RouteEnum.INTERNAL_STOCK_RECEIVE_LIST: return 'Goods Received ';
            case RouteEnum.INTERNAL_STOCK_RECEIVE_DETAILS: return 'Goods Received Details';
            case RouteEnum.BRANCH_GOODS_RETURN_LIST: return 'Goods Return';
            case RouteEnum.BRANCH_GOODS_RETURN_DETAILS: return 'Goods Return Details';
            
            case RouteEnum.INTERNAL_STOCK_TRANSFER_LIST: return 'Stock Transfer';
            case RouteEnum.INTERNAL_STOCK_TRANSFER_DETAILS: return 'Stock Transfer Details';
            

            //Reports
            case RouteEnum.REPORTS: return 'Reports';
            case RouteEnum.MEDICAL_CERTIFICATE_REPORT: return 'Medical Certificates Report';
            case RouteEnum.PATIENT_REPORT: return 'Patients Report';
            case RouteEnum.PAYMENT_REPORT: return 'Payments Report';
            case RouteEnum.AGINGS_REPORT: return 'Agings Report';
            case RouteEnum.CLINICAL_SERVICES_INVENTORY_REPORT: return 'Clinic Services Inventory Report';
            case RouteEnum.APPOINTMENT_REPORT: return 'Appointments Report';
            case RouteEnum.QUEUE_REPORT: return 'Queue Report';
            case RouteEnum.INVENTORY_REPORT: return 'Inventory Report';
            case RouteEnum.DISPENSE_REPORT: return 'Dispense Report';
            case RouteEnum.STOCK_ADJUSTMENT_REPORT: return 'Stock Adjustment Report';
            case RouteEnum.END_OF_DAY_REPORT: return 'End of Day Report';
            case RouteEnum.INVOICE_REPORT: return 'Invoices Report';
            case RouteEnum.REPORT_SUMMARY: return 'Report Summary';

            //Maintenance
            case RouteEnum.MAINTENANCE: return 'Maintenance';
            case RouteEnum.GENERAL_SETTINGS: return 'General Settings';
            case RouteEnum.ROLE_MAINTENANCE_LIST: return 'Role & Accessibility';
            case RouteEnum.ROLE_MAINTENANCE_DETAILS: return 'Role & Accessibility Details';
            case RouteEnum.CLINIC_MAINTENANCE_LIST: return 'Clinic Maintenance';
            case RouteEnum.CLINIC_MAINTENANCE_DETAILS: return 'Clinic Details';
            case RouteEnum.INSURANCE_MAINTENANCE_LIST: return 'Insurance/Panel Maintenance';
            case RouteEnum.INSURANCE_MAINTENANCE_DETAILS: return 'Insurance/Panel Details';
            case RouteEnum.SUPPLIER_MAINTENANCE_LIST: return 'Supplier Maintenance';
            case RouteEnum.SUPPLIER_MAINTENANCE_DETAILS: return 'Supplier Details';
            case RouteEnum.USER_MAINTENANCE_LIST: return 'User Maintenance';
            case RouteEnum.USER_MAINTENANCE_DETAILS: return 'User Details';
            case RouteEnum.SERVICE_MAINTENANCE_LIST: return 'Service Maintenance';
            case RouteEnum.SERVICE_MAINTENANCE_DETAILS: return 'Service Details';
            case RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST: return 'Service Category Maintenance';
            case RouteEnum.PRICE_GROUP_MAINTENANCE_LIST: return 'Price Group Maintenance';
            case RouteEnum.INVENTORY_MAINTENANCE_LIST: return 'Inventory Maintenance';
            case RouteEnum.INVENTORY_SETUP_LIST: return 'Inventory Setup';

            //Prints
            case RouteEnum.PRINT_PG_INVOICE_PAGE: return 'Print Posted Group Invoice';
            case RouteEnum.PRINT_GROUP_INVOICE_PAGE: return 'Print Group Invoice';
            case RouteEnum.PRINT_INVOICE_PAGE:
            case RouteEnum.PRINT_PATIENT_INVOICE:
                return 'Print Invoice';
            case RouteEnum.PRINT_MEDICAL_CERTIFICATE: return 'Print Medical Certificate';
            case RouteEnum.PRINT_OFFICIAL_RECEIPT: return 'Print Official Receipt';
            case RouteEnum.PRINT_PRESCRIPTION:
            case RouteEnum.PRINT_PATIENT_PRESCRIPTION:
                return 'Print Prescription';
            case RouteEnum.PRINT_TIMESLIP: return 'Print Time Slip';
            case RouteEnum.PRINT_PO: return 'Print Purchase Order';
            case RouteEnum.PRINT_RECEIVE_ORDER: return 'Print Receive Order';
            case RouteEnum.PRINT_CN: return 'Print Credit Note';
            case RouteEnum.PRINT_PRESCRIPTION_LABEL:
            case RouteEnum.PRINT_PATIENT_PRESCRIPTION_LABEL:
                return 'Print Prescription Label';
            case RouteEnum.PRINT_RECORD_LABEL: return 'Print Record Label';
            case RouteEnum.PRINT_QUOTATION: return 'Print Quotation';
            case RouteEnum.PRINT_STOCK_TRANSFER: return 'Print Stock Transfer';
            case RouteEnum.PRINT_STOCK_RECEIVED: return 'Print Stock Received';
            case RouteEnum.PRINT_MEDICAL_REPORT: return 'Print Medical Certificate Report';
            case RouteEnum.PRINT_ENDOFDAY_REPORT: return 'Print End of Day Report';
            case RouteEnum.PRINT_QUEUE_REPORT: return 'Print Queue Report';
            case RouteEnum.PRINT_APPOINTMENTS_REPORT: return 'Print Appointments Report';
            case RouteEnum.PRINT_CLINICAL_SERVICES_INVENTORY_REPORT: return 'Print Clinical Services Inventory Report';
            case RouteEnum.PRINT_AGINGS_REPORT: return 'Print Agings Report';
            case RouteEnum.PRINT_PAYMENT_REPORT: return 'Print Payments Report';
            case RouteEnum.PRINT_PATIENT_REPORT: return 'Print Patients Report';
            case RouteEnum.PRINT_INVOICE_REPORT: return 'Print Invoice Report';
            case RouteEnum.PRINT_DISPENSE_REPORT: return 'Print Dispense Report';
            case RouteEnum.PRINT_STOCK_ADJUSTMENT_REPORT: return 'Print Stock Adjustment Report';

            case RouteEnum.PROFILE: return 'Profile';
            default: return '';
        }
    },

    getRouteParent: function (routeEnum) {
        switch (routeEnum) {
            //Queue
            case RouteEnum.QUEUE:
            case RouteEnum.TODAY_QUEUE_LIST:
            case RouteEnum.PAST_QUEUE_LIST:
                return 'Queue';

            //Patients
            case RouteEnum.PATIENT_LIST: return 'Patients';
            case RouteEnum.NEW_REGISTERED_PATIENT_LIST: return 'Patients';
            case RouteEnum.NEW_PATIENT: return 'New Patient';
            case RouteEnum.PATIENT_DETAILS:
            case RouteEnum.PATIENT_DETAILS_DRUG_ALLERGY:
            case RouteEnum.PATIENT_DETAILS_INFO:
            case RouteEnum.PATIENT_DETAILS_APPOINTMENT:
            case RouteEnum.PATIENT_DETAILS_DISPENSING:
            case RouteEnum.PATIENT_DETAILS_PRESCRIPTION:
            case RouteEnum.PATIENT_DETAILS_INVOICE:
                return 'Patients';

            //Appointments
            case RouteEnum.APPOINTMENT_LIST:
            case RouteEnum.APPOINTMENT_CALENDAR:
                return 'Appointment';

            //Prescription
            case RouteEnum.PRESCRIPTION_LIST:
            case RouteEnum.PRESCRIPTION_DETAILS:
                return 'Prescription';

            //Invoices
            case RouteEnum.INVOICE_LIST:
            case RouteEnum.INVOICE_DETAILS:
            case RouteEnum.CREDIT_NOTE_LIST:
            case RouteEnum.CREDIT_NOTE_DETAILS:
            case RouteEnum.INVOICE_DETAILED_SUMMARY:
            case RouteEnum.GROUP_INVOICE_LIST:
            case RouteEnum.POSTED_GROUP_INVOICE_LIST:
            case RouteEnum.POSTED_GROUP_INVOICE_DETAILS:
            case RouteEnum.QOUTATION_LIST:
            case RouteEnum.QUOTATION_DETAILS:
            case RouteEnum.MAKE_PAYMENT_FORM:
            case RouteEnum.REFUND_PAYMENT_FORM:
                return 'Invoices';

            //Inventory
            case RouteEnum.INVENTORY_LIST:
            case RouteEnum.INVENTORY_DETAILS:
            case RouteEnum.RECEIVABLE_ORDER_LIST:
            case RouteEnum.RECEIVABLE_ORDER_DETAILS:
            case RouteEnum.PURCHASE_ORDER_LIST:
            case RouteEnum.PURCHASE_ORDER_DETAILS:
            case RouteEnum.INTERNAL_STOCK_TRANSFER_LIST:
            case RouteEnum.INTERNAL_STOCK_TRANSFER_DETAILS:
            case RouteEnum.INTERNAL_STOCK_RECEIVE_LIST:
            case RouteEnum.INTERNAL_STOCK_RECEIVE_DETAILS:
            case RouteEnum.PURCHASE_RETURN_LIST:
            case RouteEnum.PURCHASE_RETURN_DETAILS:
            case RouteEnum.BRANCH_GOODS_RETURN_LIST:
            case RouteEnum.BRANCH_GOODS_RETURN_DETAILS:
                return 'Inventory';

            //Reports
            case RouteEnum.MEDICAL_CERTIFICATE_REPORT:
            case RouteEnum.PATIENT_REPORT:
            case RouteEnum.PAYMENT_REPORT:
            case RouteEnum.AGINGS_REPORT:
            case RouteEnum.CLINICAL_SERVICES_INVENTORY_REPORT:
            case RouteEnum.APPOINTMENT_REPORT:
            case RouteEnum.QUEUE_REPORT:
            // case RouteEnum.INVENTORY_REPORT:
            case RouteEnum.DISPENSE_REPORT:
            case RouteEnum.STOCK_ADJUSTMENT_REPORT:
            case RouteEnum.END_OF_DAY_REPORT:
            case RouteEnum.INVOICE_REPORT:
            case RouteEnum.REPORT_SUMMARY:
                return 'Reports';

            //Maintenance
            case RouteEnum.GENERAL_SETTINGS:
            case RouteEnum.ROLE_MAINTENANCE_LIST:
            case RouteEnum.ROLE_MAINTENANCE_DETAILS:
            case RouteEnum.CLINIC_MAINTENANCE_LIST:
            case RouteEnum.CLINIC_MAINTENANCE_DETAILS:
            case RouteEnum.INSURANCE_MAINTENANCE_LIST:
            case RouteEnum.INSURANCE_MAINTENANCE_DETAILS:
            case RouteEnum.SUPPLIER_MAINTENANCE_LIST:
            case RouteEnum.SUPPLIER_MAINTENANCE_DETAILS:
            case RouteEnum.USER_MAINTENANCE_LIST:
            case RouteEnum.USER_MAINTENANCE_DETAILS:
            case RouteEnum.SERVICE_MAINTENANCE_LIST:
            case RouteEnum.SERVICE_MAINTENANCE_DETAILS:
            case RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST:
            case RouteEnum.PRICE_GROUP_MAINTENANCE_LIST:
            case RouteEnum.INVENTORY_MAINTENANCE_LIST:
            case RouteEnum.INVENTORY_MAINTENANCE_DETAILS:
            case RouteEnum.INVENTORY_SETUP_LIST:
                return 'Maintenance';

            // //Prints
            // case RouteEnum.PRINT_INVOICE_PAGE: return 'Print Invoice';
            // case RouteEnum.PRINT_MEDICAL_CERTIFICATE: return 'Print Medical Certificate';
            // case RouteEnum.PRINT_OFFICIAL_RECEIPT: return 'Print Official Receipt';
            // case RouteEnum.PRINT_PRESCRIPTION: return 'Print Prescription';
            case RouteEnum.PRINT_MEDICAL_REPORT: return 'Print Medical Report';
            case RouteEnum.PRINT_ENDOFDAY_REPORT: return 'Print End Of Day Report';

            //PROFILE
            case RouteEnum.PROFILE: return 'My Profile';
            default: return '';
        }
    }
}

export default RouteHelper;