import {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
} from "./apiClient";

//#region 
const InvoiceUrl = 'invoices';
//#endregion 

const InvoiceService = {
    getInvoiceStatuses: async () => {
        var _url = InvoiceUrl + "/status";
        var res = await getRequest(_url);

        return res.data;
    },
    getAllInvoices: async (queryString) => {
        var _url = InvoiceUrl + queryString;
        var res = await getRequest(_url);

        return res.data;
    },
    getDetailsInvoicesList: async (queryString) => {
        var _url = InvoiceUrl + "/detailed" + queryString;
        var res = await getRequest(_url);

        return res.data;
    },
    createInvoice: async (param) => {
        var res = await postRequest(InvoiceUrl, param);

        return res;
    },
    getInvoiceById: async (id) => {
        var _url = InvoiceUrl + "/" + id;
        var res = await getRequest(_url);

        return res.data;
    },
    getInvoiceByPatientIdRecent: async (patientid) => {
        var _url = InvoiceUrl + "/patient/" + patientid + "/recent";
        var res = await getRequest(_url);

        return res.data;
    },
    putInvoice: async (param, id) => {
        var _url = InvoiceUrl + "/" + id;
        var res = await putRequest(_url, param);

        return res;
    },
    putPayment: async (param, id) => {
        var _url = InvoiceUrl + "/" + id + "/pay";
        var res = await putRequest(_url, param);

        return res;
    },
    deleteInvoice: async (id) => {
        var _url = InvoiceUrl + "/" + id;
        var res = await deleteRequest(_url);

        return res;
    },
    addInvoicePayment: async (id) => {
        var _url = InvoiceUrl + "/" + id + "/addInvoice";
        var res = await getRequest(_url);

        return res.data;
    },
    payInvoice: async (id) => {
        var _url = InvoiceUrl + "/" + id + "/pay";
        var res = await getRequest(_url);

        return res.data;
    },
    getInvoiceDispenses: async (queryString, isForList = false) => {
        var _url = InvoiceUrl + "/dispenses" + queryString + "&isForList=" + isForList;
        var res = await getRequest(_url);

        return res.data;
    },
    getQueueInvoice: async (id) => {
        var _url = InvoiceUrl + "/queue/" + id;
        var res = await getRequest(_url);

        return res.data;
    },
}

export default InvoiceService
