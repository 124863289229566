import GeneralSettingsService from '../../services/generalsettingsservice';
import LocalStorageHelper from "../LocalStorageHelper";
import { DefaultImages } from '../Constants/ImageConstants';
import ClinicService from '../../services/clinicservice';

const SettingsHelper = {
    UpdateGenSettings: async function (clinicId = null) {
        const userData = LocalStorageHelper.getUserData();
        let _isLoggedIn = LocalStorageHelper.isLoggedIn();
        let settingsData = {
            clinicId: (clinicId ? clinicId : userData && userData.clinicId && userData.clinicId != null && userData.clinicId != "" ?
                userData.clinicId : 0
            ),
            clinicName: (userData && userData.clinicName && userData.clinicName != null && userData.clinicName != "" ?
                userData.clinicName : "Alpro Clinic"
            ),
            sidebarLogoLightMode: DefaultImages.LOGO_LIGHT,
            sidebarLogoDarkMode: DefaultImages.LOGO_DARK,
            sidebarSmallLogoLightMode: DefaultImages.SMALL_LOGO_LIGHT,
            sidebarSmallLogoDarkMode: DefaultImages.SMALL_LOGO_LIGHT,
            documentLogo: DefaultImages.DOCUMENT_LOGO,
            favicon: DefaultImages.FAV_ICON,
            language: "English",
            taxName: (userData && userData.clinicTaxName && userData.clinicTaxName != null && userData.clinicTaxName != "" ?
                userData.clinicTaxName : "GST"
            ),
            taxPercentage: (userData && userData.clinicTaxValue && userData.clinicTaxValue != null && userData.clinicTaxValue != "" ?
                userData.clinicTaxValue : 0
            ),
        };

        let _getConfigSettings = await GeneralSettingsService.getGeneralSettings();
        if (_getConfigSettings.errorCode == 401) {

        } else if (_getConfigSettings && _getConfigSettings.data && _getConfigSettings.data.data && _getConfigSettings.data.data.length > 0) {
            _getConfigSettings.data.data.forEach(item => {
                if (item.key && item.key != null && item.key != "") {
                    switch (item.key) {
                        case "sidebarLogoLightMode":
                        case "sidebarLogoDarkMode":
                        case "sidebarSmallLogoLightMode":
                        case "sidebarSmallLogoDarkMode":
                        case "documentLogo":
                        case "favicon":
                            if (item.fileUrl && item.fileUrl != null && item.fileUrl != "") {
                                settingsData[item.key] = process.env.REACT_APP_API_URL + "/api/" + item.fileUrl;
                            }
                            break;
                        case "language":
                            // case "taxName":
                            // case "taxPercentage":
                            settingsData[item.key] = item.value;
                            break;
                    }
                }
            });
        }

        LocalStorageHelper.saveGeneralSettingsData(settingsData)
        if (_isLoggedIn) {

            LocalStorageHelper.saveUserData({
                ...userData,
                clinicTaxName: settingsData.taxName,
                clinicTaxValue: settingsData.taxPercentage,
            });

            await this.UpdateClinicData(clinicId ? clinicId : userData.clinicId);
        }
    },
    UpdateFavIcon: async function () {
        let settingsData = LocalStorageHelper.getGeneralSettingsData();
        let clinicData = LocalStorageHelper.getClinicData();
        let faviconLink = document.querySelector("link[rel~='icon']");

        if (!faviconLink) {
            faviconLink = document.createElement('link');
            faviconLink.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(faviconLink);
        }

        faviconLink.href = clinicData?.favicon ?? settingsData?.favicon ?? DefaultImages.FAV_ICON;
    },
    UpdateClinicData: async function (clinicId = null) {
        const userData = LocalStorageHelper.getUserData();
        const BASE_URL = process.env.REACT_APP_API_URL + '/api/';
        const settingsData = LocalStorageHelper.getGeneralSettingsData(); //do not move this
        const clinicFromAPI = await ClinicService.getClinicById(clinicId ? clinicId : userData.clinicId);
        const clinicData = clinicFromAPI?.data;
        let clinicDict = ({
            address: '',
            telNo: '',
            bankDetail: '',
            registrationNo: '',
            clinicName: (userData?.clinicName ?? "Alpro Clinic"),
            sidebarLogoLightMode: DefaultImages.LOGO_LIGHT,
            sidebarLogoDarkMode: DefaultImages.LOGO_DARK,
            sidebarSmallLogoLightMode: DefaultImages.SMALL_LOGO_LIGHT,
            sidebarSmallLogoDarkMode: DefaultImages.SMALL_LOGO_LIGHT,
            documentLogo: DefaultImages.DOCUMENT_LOGO,
            favicon: DefaultImages.FAV_ICON,
            language: "English",
        });
        const clinicSettings = clinicData?.settings;
        const sideBarLight = clinicSettings?.find(x => x.key == "sidebarLogoLightMode")?.fileUrl
        const sideBarLightSmall = clinicSettings?.find(x => x.key == "sidebarSmallLogoLightMode")?.fileUrl
        const sideBarDark = clinicSettings?.find(x => x.key == "sidebarLogoDarkMode")?.fileUrl
        const sideBarDarkSmall = clinicSettings?.find(x => x.key == "sidebarSmallLogoDarkMode")?.fileUrl
        const docLogo = clinicSettings?.find(x => x.key == "documentLogo")?.fileUrl
        const favicon = clinicSettings?.find(x => x.key == "favicon")?.fileUrl

        clinicDict.sidebarLogoLightMode = sideBarLight ? BASE_URL + sideBarLight : settingsData?.sidebarLogoLightMode ?? DefaultImages.LOGO_LIGHT;
        clinicDict.sidebarLogoDarkMode = sideBarDark ? BASE_URL + sideBarDark : settingsData?.sidebarLogoDarkMode ?? DefaultImages.LOGO_DARK;
        clinicDict.sidebarSmallLogoLightMode = sideBarLightSmall ? BASE_URL + sideBarLightSmall : settingsData?.sidebarSmallLogoLightMode ?? DefaultImages.SMALL_LOGO_LIGHT;
        clinicDict.sidebarSmallLogoDarkMode = sideBarDarkSmall ? BASE_URL + sideBarDarkSmall : settingsData?.sidebarSmallLogoDarkMode ?? DefaultImages.SMALL_LOGO_LIGHT;
        clinicDict.documentLogo = docLogo ? BASE_URL + docLogo : settingsData?.documentLogo ?? DefaultImages.DOCUMENT_LOGO;
        clinicDict.favicon = favicon ? BASE_URL + favicon : settingsData?.favicon ?? DefaultImages.FAV_ICON;

        if (clinicData) {
            var address = clinicData.streetAddress + (clinicData.zipCode != null && clinicData.zipCode !== "" ? ", " + clinicData.zipCode : "")
                + (clinicData.city != null && clinicData.city !== "" ? " " + clinicData.city : "")
                + (clinicData.state != null && clinicData.state !== "" ? ", " + clinicData.state : "")
                + (clinicData.country != null && clinicData.country !== "" ? ", " + clinicData.country : "");
            var code1 = (clinicData.countryCode != null && clinicData.countryCode !== "" ? clinicData.countryCode : "-");
            var no1 = (clinicData.contactNumber != null && clinicData.contactNumber !== "" ? clinicData.contactNumber : "-");
            var code2 = (clinicData.contactPersonCountryCode != null && clinicData.contactPersonCountryCode !== "" ? clinicData.contactPersonCountryCode : "-");
            var no2 = (clinicData.contactPersonContactNumber != null && clinicData.contactPersonContactNumber !== "" ? clinicData.contactPersonContactNumber : "-");
            var telNo = "TEL: " + code1 + " " + no1 + " | HP: " + code2 + " " + no2;
            var bank = "BANK DETAIL : " + (clinicData.name != null && clinicData.name !== "" ? clinicData.name : "-")
                + " | " + (clinicData.bankAccountNo != null && clinicData.bankAccountNo !== "" ? clinicData.bankAccountNo : "-")
                + " | " + (clinicData.bankName != null & clinicData.bankName !== "" ? clinicData.bankName : "-");

            clinicDict.clinicName = clinicData.name
            clinicDict.address = address
            clinicDict.telNo = telNo
            clinicDict.bankDetail = bank
            clinicDict.registrationNo = clinicData.registrationNo
        }
        LocalStorageHelper.saveClinicData(clinicDict)
        this.UpdateFavIcon(clinicDict.favicon);
    }
}

export default SettingsHelper;