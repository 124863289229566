import { RouteEnum } from '../../../../utilities/Enums/RouteEnums';
import RouteHelper from '../../../../utilities/Helpers/RouteHelper';

import { 
    AccessEnum,
    QueueAccessEnum,
    PatientAccessEnum,
    ReportsAccessEnum,
    InvoicesAccessEnum,
    InventoryAccessEnum,
    AppointmentAccessEnum,
    MaintenanceAccessEnum,
    PrescriptionAccessEnum,
} from '../../../../utilities/Enums/AccesibilityEnum';

import {
    HomeOutlinedIcon,
    PeopleAltOutlinedIcon,
    PersonOutlineOutlinedIcon,
    CalendarTodayOutlinedIcon,
    DescriptionOutlinedIcon,
    HeartBeatIcon,
    InsertDriveFileOutlinedIcon,
    AttachMoneyOutlinedIcon,
    GridViewOutlinedIcon,

    KeyboardArrowRightOutlinedIcon,
    CubeIcon,
    PieChartOutlineOutlinedIcon,
    SettingsOutlinedIcon,
    KeyboardArrowDownOutlinedIcon,

    AccountCircleRoundedIcon,
    ChatOutlinedIcon,
    EventAvailableOutlinedIcon,
    OutputOutlinedIcon,
}
    from '../../../../utilities/Icons/Icons';

export const MenuItems = [
    {
        name: RouteHelper.getRouteDescription(RouteEnum.DASHBOARD),
        navigateTo: RouteEnum.DASHBOARD,
        icon: <HomeOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: false,
        tabName: "Dashboard",
        subMenuItems: [],
        parentName: null,
        accessId : AccessEnum.DASHBOARD,
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.QUEUE),
        navigateTo: RouteEnum.TODAY_QUEUE_LIST,
        icon: <PeopleAltOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Queue",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.TODAY_QUEUE_LIST),
                navigateTo: RouteEnum.TODAY_QUEUE_LIST,
                icon: null,
                tabName: "TodaysQueue",
                parentName: "Queue",
                accessId : QueueAccessEnum.QUEUE_TODAY_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.PAST_QUEUE_LIST),
                navigateTo: RouteEnum.PAST_QUEUE_LIST,
                icon: null,
                tabName: "PastQueue",
                parentName: "Queue",
                accessId : QueueAccessEnum.QUEUE_PAST_VIEW,
            },
        ],
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.PATIENT),
        navigateTo: RouteEnum.PATIENT_LIST,
        icon: <PersonOutlineOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Patients",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.PATIENT_LIST),
                navigateTo: RouteEnum.PATIENT_LIST,
                icon: null,
                tabName: "PatientsList",
                parentName: "Patients",
                accessId : PatientAccessEnum.PATIENT_LIST_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.NEW_REGISTERED_PATIENT_LIST),
                navigateTo: RouteEnum.NEW_REGISTERED_PATIENT_LIST,
                icon: null,
                tabName: "PatientsListNew",
                parentName: "Patients",
                accessId : PatientAccessEnum.PATIENT_NEW_VIEW,
            },
        ],
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.APPOINTMENT),
        navigateTo: RouteEnum.APPOINTMENT_LIST,
        icon: <CalendarTodayOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Appointment",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.APPOINTMENT_LIST),
                navigateTo: RouteEnum.APPOINTMENT_LIST,
                icon: null,
                tabName: "AppointmentList",
                parentName: "Appointment",
                accessId : AppointmentAccessEnum.APPOINTMENT_LIST_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.APPOINTMENT_CALENDAR),
                navigateTo: RouteEnum.APPOINTMENT_CALENDAR,
                icon: null,
                tabName: "AppointmentCalendar",
                parentName: "Appointment",
                accessId: AppointmentAccessEnum.APPOINTMENT_CALENDAR_VIEW
            },
        ],
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.PRESCRIPTION_LIST),
        navigateTo: RouteEnum.PRESCRIPTION_LIST,
        icon: <DescriptionOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: false,
        tabName: "Prescription",
        parentName: null,
        accessId : PrescriptionAccessEnum.PRESCRIPTION_VIEW,
        subMenuItems: [],
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.INVOICE_LIST),
        navigateTo: RouteEnum.INVOICE_LIST,
        icon: <InsertDriveFileOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Invoices",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INVOICE_LIST),
                navigateTo: RouteEnum.INVOICE_LIST,
                icon: null,
                tabName: "InvoicesList",
                parentName: "Invoices",
                accessId : InvoicesAccessEnum.INVOICES_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INVOICE_DETAILED_SUMMARY),
                navigateTo: RouteEnum.INVOICE_DETAILED_SUMMARY,
                icon: null,
                tabName: "InvoicesDetailedSummary",
                parentName: "Invoices",
                accessId : InvoicesAccessEnum.INVOICES_DETAILED_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.GROUP_INVOICE_LIST),
                navigateTo: RouteEnum.GROUP_INVOICE_LIST,
                icon: null,
                tabName: "GroupInvoices",
                parentName: "Invoices",
                accessId : InvoicesAccessEnum.GROUP_INVOICES_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.POSTED_GROUP_INVOICE_LIST),
                navigateTo: RouteEnum.POSTED_GROUP_INVOICE_LIST,
                icon: null,
                tabName: "PostedGroupInvoices",
                parentName: "Invoices",
                accessId : InvoicesAccessEnum.POSTED_GROUP_INVOICES_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.QOUTATION_LIST),
                navigateTo: RouteEnum.QOUTATION_LIST,
                icon: null,
                tabName: "Quotation",
                parentName: "Invoices",
                accessId : InvoicesAccessEnum.QUOTATION_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.CREDIT_NOTE_LIST),
                navigateTo: RouteEnum.CREDIT_NOTE_LIST,
                icon: null,
                tabName: "CreditNote",
                parentName: "Invoices",
                accessId : InvoicesAccessEnum.CREDIT_NOTE_VIEW,
            },
        ],
    },
    // {
    //     name: 'Payments',
    //     navigateTo: '/Payments',
    //     icon: <AttachMoneyOutlinedIcon className="webAppMenuItemBtnIcon" />,
    //     hasSubMenu: false,
    //     tabName: "Payments",
    //     parentName: null,
    //     subMenuItems: [],
    // },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.INVENTORY_LIST),
        navigateTo: RouteEnum.INVENTORY_LIST,
        icon: <CubeIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Inventory",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INVENTORY_LIST),
                navigateTo: RouteEnum.INVENTORY_LIST,
                icon: null,
                tabName: "Inventory",
                parentName: "Inventory",
                accessId : InventoryAccessEnum.INVENTORY_VIEW,
            },
            // {
            //     name: 'Receivable Order',
            //     navigateTo: RouteEnum.RECEIVABLE_ORDER_LIST,
            //     icon: null,
            //     tabName: "ReceivableOrder",
            //     parentName: "Inventory",
            // },
            // {
            //     name: 'Purchase Order',
            //     navigateTo: RouteEnum.PURCHASE_ORDER_LIST,
            //     icon: null,
            //     tabName: "PurchaseOrder",
            //     parentName: "Inventory",
            // },
            // {
            //     name: 'Internal Stock Transfer',
            //     navigateTo: RouteEnum.INTERNAL_STOCK_TRANSFER_LIST,
            //     icon: null,
            //     tabName: "InternalStockTransfer",
            //     parentName: "Inventory",
            // },
            // {
            //     name: 'Internal Stock Receive',
            //     navigateTo: RouteEnum.INTERNAL_STOCK_RECEIVE_LIST,
            //     icon: null,
            //     tabName: "InternalStockReceive",
            //     parentName: "Inventory",
            // },



            {
                name: RouteHelper.getRouteDescription(RouteEnum.VENDOR),
                navigateTo: null,
                icon: null,
                tabName: "Vendor",
                parentName: null,
                hasSubMenu: true,
                subMenuItems: [
                    {
                        name: RouteHelper.getRouteDescription(RouteEnum.RECEIVABLE_ORDER_LIST),
                        navigateTo: RouteEnum.RECEIVABLE_ORDER_LIST,
                        icon: null,
                        tabName: "ReceivableOrder",
                        parentName: "Vendor",
                        accessId : InventoryAccessEnum.VENDOR_GOODS_RECEIVED_VIEW,
                    },
                    {
                        name: RouteHelper.getRouteDescription(RouteEnum.PURCHASE_ORDER_LIST),
                        navigateTo: RouteEnum.PURCHASE_ORDER_LIST,
                        icon: null,
                        tabName: "PurchaseOrder",
                        parentName: "Vendor",
                        accessId : InventoryAccessEnum.VENDOR_PURCHASE_ORDER_VIEW,
                    },
                    {
                        name: RouteHelper.getRouteDescription(RouteEnum.PURCHASE_RETURN_LIST),
                        navigateTo: RouteEnum.PURCHASE_RETURN_LIST,
                        icon: null,
                        tabName: "PurchaseReturn",
                        parentName: "Vendor",
                        accessId : InventoryAccessEnum.VENDOR_PURCHASE_RETURN_VIEW,
                    },
                ],
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.BRANCH),
                navigateTo: null,
                icon: null,
                tabName: "Branch",
                parentName: null,
                hasSubMenu: true,
                subMenuItems: [
                    {
                        name: RouteHelper.getRouteDescription(RouteEnum.INTERNAL_STOCK_RECEIVE_LIST),
                        navigateTo: RouteEnum.INTERNAL_STOCK_RECEIVE_LIST,
                        icon: null,
                        tabName: "InternalStockReceive",
                        parentName: "Branch",
                        accessId : InventoryAccessEnum.BRANCH_GOODS_RECEIVED_VIEW,
                    },
                    {
                        name: RouteHelper.getRouteDescription(RouteEnum.BRANCH_GOODS_RETURN_LIST),
                        navigateTo: RouteEnum.BRANCH_GOODS_RETURN_LIST,
                        icon: null,
                        tabName: "BranchGoodsReturn",
                        parentName: "Branch",
                        accessId : InventoryAccessEnum.BRANCH_GOODS_RETURN_VIEW,
                    },
                    {
                        name: RouteHelper.getRouteDescription(RouteEnum.INTERNAL_STOCK_TRANSFER_LIST),
                        navigateTo: RouteEnum.INTERNAL_STOCK_TRANSFER_LIST,
                        icon: null,
                        tabName: "InternalStockTransfer",
                        parentName: "Branch",
                        accessId : InventoryAccessEnum.BRANCH_STOCK_TRANSFER_VIEW,
                    },
                ],
            },
        ],
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.REPORTS),
        navigateTo: RouteEnum.REPORTS,
        icon: <PieChartOutlineOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Reports",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.MEDICAL_CERTIFICATE_REPORT),
                navigateTo: RouteEnum.MEDICAL_CERTIFICATE_REPORT,
                icon: null,
                tabName: "MedicalCertificate",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_MEDICAL_CERIFICATE_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INVOICE_REPORT),
                navigateTo: RouteEnum.INVOICE_REPORT,
                icon: null,
                tabName: "InvoicesReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_INVOICE_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.PATIENT_REPORT),
                navigateTo: RouteEnum.PATIENT_REPORT,
                icon: null,
                tabName: "PatientsReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_PATIENTS_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.PAYMENT_REPORT),
                navigateTo: RouteEnum.PAYMENT_REPORT,
                icon: null,
                tabName: "PaymentsReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_PAYMENTS_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.END_OF_DAY_REPORT),
                navigateTo: RouteEnum.END_OF_DAY_REPORT,
                icon: null,
                tabName: "EndOfDayReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_ENDOFDAY_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.AGINGS_REPORT),
                navigateTo: RouteEnum.AGINGS_REPORT,
                icon: null,
                tabName: "AgingsReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_AGINGS_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.CLINICAL_SERVICES_INVENTORY_REPORT),
                navigateTo: RouteEnum.CLINICAL_SERVICES_INVENTORY_REPORT,
                icon: null,
                tabName: "ClinicServicesInventoryReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_CLINIC_SERIVCES_VIEW,
            },
            // {
            //     name: RouteHelper.getRouteDescription(RouteEnum.INVENTORY_REPORT),
            //     navigateTo: RouteEnum.INVENTORY_REPORT,
            //     icon: null,
            //     tabName: "InventoryReport",
            //     parentName: "Reports",
            // },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.DISPENSE_REPORT),
                navigateTo: RouteEnum.DISPENSE_REPORT,
                icon: null,
                tabName: "DispenseReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_DISPENSE_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.STOCK_ADJUSTMENT_REPORT),
                navigateTo: RouteEnum.STOCK_ADJUSTMENT_REPORT,
                icon: null,
                tabName: "StockAdjustmentReport",
                parentName: "Reports",
                accessId: ReportsAccessEnum.REPORT_STOCK_ADJUSTMENT_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.APPOINTMENT_REPORT),
                navigateTo: RouteEnum.APPOINTMENT_REPORT,
                icon: null,
                tabName: "AppointmentsReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_APPOINTMENTS_VIEW,
            },
            // {
            //     name: 'Dispense',
            //     navigateTo: '/DispenseReport',
            //     icon: null,
            //     tabName: "DispenseReport",
            //     parentName: "Reports",
            // },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.QUEUE_REPORT),
                navigateTo: RouteEnum.QUEUE_REPORT,
                icon: null,
                tabName: "QueueReport",
                parentName: "Reports",
                accessId : ReportsAccessEnum.REPORT_QUEUE_VIEW,
            },
            // {
            //     name: 'Medical Reports',
            //     navigateTo: '/MedicalReports',
            //     icon: null,
            //     tabName: "MedicalReports",
            // },
            // {
            //     name: 'Invoice Reports',
            //     navigateTo: '/InvoiceReports',
            //     icon: null,
            //     tabName: "InvoiceReports",
            // },
            // {
            //     name: 'Patient Reports',
            //     navigateTo: '/PatientReports',
            //     icon: null,
            //     tabName: "PatientReports",
            // },
        ],
    },
    {
        name: RouteHelper.getRouteDescription(RouteEnum.MAINTENANCE),
        navigateTo: RouteEnum.MAINTENANCE,
        icon: <SettingsOutlinedIcon className="webAppMenuItemBtnIcon" />,
        hasSubMenu: true,
        tabName: "Maintenance",
        parentName: null,
        subMenuItems: [
            {
                name: RouteHelper.getRouteDescription(RouteEnum.GENERAL_SETTINGS),
                navigateTo: RouteEnum.GENERAL_SETTINGS,
                icon: null,
                tabName: "GeneralSettings",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_GENERAL_SETTINGS_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.ROLE_MAINTENANCE_LIST),
                navigateTo: RouteEnum.ROLE_MAINTENANCE_LIST,
                icon: null,
                tabName: "RoleAndAccessibilityMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_ROLE_ACCESS_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.CLINIC_MAINTENANCE_LIST),
                navigateTo: RouteEnum.CLINIC_MAINTENANCE_LIST,
                icon: null,
                tabName: "ClinicMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_CLINIC_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INSURANCE_MAINTENANCE_LIST),
                navigateTo: RouteEnum.INSURANCE_MAINTENANCE_LIST,
                icon: null,
                tabName: "InsurancePanelMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_INSURANCE_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.SUPPLIER_MAINTENANCE_LIST),
                navigateTo: RouteEnum.SUPPLIER_MAINTENANCE_LIST,
                icon: null,
                tabName: "SupplierMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_SUPPLIER_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INVENTORY_SETUP_LIST),
                navigateTo: RouteEnum.INVENTORY_SETUP_LIST,
                icon: null,
                tabName: "InventorySetup",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_INVENTORY_SETUP_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.INVENTORY_MAINTENANCE_LIST),
                navigateTo: RouteEnum.INVENTORY_MAINTENANCE_LIST,
                icon: null,
                tabName: "InventoryMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_INVENTORY_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.USER_MAINTENANCE_LIST),
                navigateTo: RouteEnum.USER_MAINTENANCE_LIST,
                icon: null,
                tabName: "UserMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_USER_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.SERVICE_MAINTENANCE_LIST),
                navigateTo: RouteEnum.SERVICE_MAINTENANCE_LIST,
                icon: null,
                tabName: "ServiceMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_SERVICE_VIEW,
            },
            {
                name: RouteHelper.getRouteDescription(RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST),
                navigateTo: RouteEnum.SERVICE_CATEGORY_MAINTENANCE_LIST,
                icon: null,
                tabName: "ServiceCategoryMaintenance",
                parentName: "Maintenance",
                accessId : MaintenanceAccessEnum.MAINTENANCE_SERVICE_CATEGORY_VIEW,
            },
            // {
            //     name: RouteHelper.getRouteDescription(RouteEnum.PRICE_GROUP_MAINTENANCE_LIST),
            //     navigateTo: RouteEnum.PRICE_GROUP_MAINTENANCE_LIST,
            //     icon: null,
            //     tabName: "PriceGroupMaintenance",
            //     parentName: "Maintenance",
            //     accessId : MaintenanceAccessEnum.MAINTENANCE_PRICE_GROUP_VIEW,
            // },
            // {
            //     name: 'Price Maintenance',
            //     navigateTo: '/PriceMaintenance',
            //     icon: null,
            //     tabName: "PriceMaintenance",
            //     parentName: "Maintenance",
            // },

            // {
            //     name: 'Drug Maintenance',
            //     navigateTo: '/DrugMaintenance',
            //     icon: null,
            //     tabName: "DrugMaintenance",
            //     parentName: "Maintenance",
            // },

        ],
    },
];

export const UserMenuItems = [
    {
        name: 'My Profile',
        navigateTo: RouteEnum.PROFILE,
        icon: <AccountCircleRoundedIcon className="menuItemListIcon" />,
        tabName: "Profile",
        hasDivider: false,
        parentName: null,
    },
    {
        name: 'Logout',
        navigateTo: '/Logout',
        icon: <OutputOutlinedIcon className="menuItemListIcon" />,
        tabName: "Logout",
        hasDivider: false,
        parentName: null,
    },
];