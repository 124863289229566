export const RouteEnum = {
    //DASHBAORD
    DASHBOARD: '/',
    LOGIN: '/Login',
    FORGOT_PASSWORD: '/ForgotPassword',

    //QUEUE
    QUEUE: '/Queue',
    TODAY_QUEUE_LIST: '/TodaysQueue',
    PAST_QUEUE_LIST: '/PastQueue',

    //PATIENT
    PATIENT: '/Patients',
    PATIENT_LIST: '/PatientsList',
    NEW_REGISTERED_PATIENT_LIST: '/PatientsListNew',
    NEW_PATIENT: '/NewPatient',

    PATIENT_DETAILS: '/PatientDetails',
    PATIENT_DETAILS_DRUG_ALLERGY: '/PatientDrugAllergy',
    PATIENT_DETAILS_INFO: '/PatientInformation',
    PATIENT_DETAILS_APPOINTMENT: '/PatientAppointmentHistory',
    PATIENT_DETAILS_PRESCRIPTION: '/PatientPrescriptionHistory',
    PATIENT_DETAILS_INVOICE: '/PatientInvoiceHistory',
    PATIENT_DETAILS_DISPENSING: '/PatientDispensingHistory',

    //APPOINTMENT
    APPOINTMENT: '/Appointment',
    APPOINTMENT_LIST: '/AppointmentList',
    APPOINTMENT_CALENDAR: '/AppointmentCalendar',

    //INVENTORY
    INVENTORY_LIST: '/Inventory',
    INVENTORY_DETAILS: '/InventoryDetails',
    INVENTORY_MAINTENANCE_DETAILS: '/InventoryDetails',
    VENDOR: '/Vendor',
    RECEIVABLE_ORDER_LIST: '/ReceivableOrder',
    RECEIVABLE_ORDER_DETAILS: '/ReceivableOrderDetails',
    PURCHASE_ORDER_LIST: '/PurchaseOrder',
    PURCHASE_ORDER_DETAILS: '/PurchaseOrderDetails',
    PURCHASE_RETURN_LIST: '/PurchaseReturn',
    PURCHASE_RETURN_DETAILS: '/PurchaseReturnDetails',
    BRANCH: '/Branch',
    INTERNAL_STOCK_RECEIVE_LIST: '/InternalStockReceive',
    INTERNAL_STOCK_RECEIVE_DETAILS: '/InternalStockReceiveDetails',
    BRANCH_GOODS_RETURN_LIST: '/BranchGoodsReturn',
    BRANCH_GOODS_RETURN_DETAILS: '/BranchGoodsReturnDetails',
    INTERNAL_STOCK_TRANSFER_LIST: '/InternalStockTransfer',
    INTERNAL_STOCK_TRANSFER_DETAILS: '/InternalStockTransferDetails',    

    //INVOICES
    INVOICE_LIST: '/Invoices',
    INVOICE_DETAILS: '/InvoiceDetails',
    CREDIT_NOTE_LIST: '/CreditNote',
    CREDIT_NOTE_DETAILS: '/CreditInvoiceDetails',
    INVOICE_DETAILED_SUMMARY: '/InvoicesDetailedSummary',
    GROUP_INVOICE_LIST: '/GroupInvoices',
    POSTED_GROUP_INVOICE_LIST: '/PostedGroupInvoices',
    POSTED_GROUP_INVOICE_DETAILS: '/PostedGroupInvoicesDetails',
    QOUTATION_LIST: '/Quotation',
    QUOTATION_DETAILS: '/QuotationDetails',

    //PRESCRIPTION
    PRESCRIPTION_LIST: '/Prescription',
    PRESCRIPTION_DETAILS: '/PrescriptionDetails',

    //MAINTENANCE
    MAINTENANCE: '/Maintenance',
    GENERAL_SETTINGS: '/GeneralSettings',
    ROLE_MAINTENANCE_LIST: '/RoleAndAccessibilityMaintenance',
    ROLE_MAINTENANCE_DETAILS: '/RoleAndAccessibilityDetails',
    CLINIC_MAINTENANCE_LIST: '/ClinicMaintenance',
    CLINIC_MAINTENANCE_DETAILS: '/ClinicMaintenanceDetails',
    INSURANCE_MAINTENANCE_LIST: '/InsurancePanelMaintenance',
    INSURANCE_MAINTENANCE_DETAILS: '/InsurancePanelMaintenanceDetails',
    SUPPLIER_MAINTENANCE_LIST: '/SupplierMaintenance',
    SUPPLIER_MAINTENANCE_DETAILS: '/SupplierMaintenanceDetails',
    USER_MAINTENANCE_LIST: '/UserMaintenance',
    USER_MAINTENANCE_DETAILS: '/UserMaintenanceDetails',
    SERVICE_MAINTENANCE_LIST: '/ServiceMaintenance',
    SERVICE_MAINTENANCE_DETAILS: '/ServiceMaintenanceDetails',
    SERVICE_CATEGORY_MAINTENANCE_LIST: '/ServiceCategoryMaintenance',
    // SERVICE_CATEGORY_MAINTENANCE_DETAILS: '/ServiceCategoryMaintenanceDetails',
    PRICE_GROUP_MAINTENANCE_LIST: '/PriceGroupMaintenance',
    INVENTORY_MAINTENANCE_LIST: '/InventoryMaintenance',
    INVENTORY_SETUP_LIST: '/InventorySetup',

    //REPORTS
    REPORTS: "/Reports",
    MEDICAL_CERTIFICATE_REPORT: '/MedicalCertificateReport',
    PATIENT_REPORT: '/PatientsReport',
    PAYMENT_REPORT: '/PaymentsReport',
    AGINGS_REPORT: '/AgingsReport',
    CLINICAL_SERVICES_INVENTORY_REPORT: '/ClinicServicesInventoryReport',
    APPOINTMENT_REPORT: '/AppointmentsReport',
    QUEUE_REPORT: '/QueueReport',
    // INVENTORY_REPORT: '/InventoryReport',
    DISPENSE_REPORT: '/DispenseReport',
    STOCK_ADJUSTMENT_REPORT: '/StockAdjustmentReport',
    END_OF_DAY_REPORT: '/EndOfDayReport',
    INVOICE_REPORT: '/InvoicesReport',
    REPORT_SUMMARY: '/ReportSummary',

    //PRINTS
    PRINT_INVOICE_PAGE: '/PrintInvoice',
    PRINT_INVENTORY_PURCHASERETURN: '/PrintPurchaseReturn',
    PRINT_INVENTORY_GOODSRETURN: '/PrintGoodsReturn',
    PRINT_PG_INVOICE_PAGE: '/PrintPostedGroupInvoice',
    PRINT_GROUP_INVOICE_PAGE: '/PrintGroupInvoice',
    PRINT_MEDICAL_CERTIFICATE: '/PrintMedicalCertificate',
    PRINT_OFFICIAL_RECEIPT: '/PrintOfficialReceipt',
    PRINT_PRESCRIPTION: '/PrintPrescription',
    PRINT_TIMESLIP: '/PrintTimeSlip',
    PRINT_PO: '/PrintPurchaseOrder',
    PRINT_RECEIVE_ORDER: '/PrintReceiveOrder',
    PRINT_CN: '/PrintCreditNote',
    PRINT_PRESCRIPTION_LABEL: '/PrintPrescriptionLabel',
    PRINT_RECORD_LABEL: '/PrintRecordLabel',
    PRINT_PATIENT_INVOICE: '/PrintPatientInvoice',
    PRINT_PATIENT_PRESCRIPTION_LABEL: '/PrintPatientPrescriptionLabel',
    PRINT_PATIENT_PRESCRIPTION: '/PrintPatientPrescription',
    PRINT_RECEIPT: '/PrintReceipt',
    PRINT_QUOTATION: '/PrintQuotation',
    PRINT_STOCK_TRANSFER: '/PrintStockTransfer',
    PRINT_STOCK_RECEIVED: '/PrintStockReceived',
    PRINT_MEDICAL_REPORT: '/PrintMedicalReport',
    PRINT_ENDOFDAY_REPORT: '/PrintEndOfDayReport',
    PRINT_QUEUE_REPORT: '/PrintQueueReport',
    PRINT_APPOINTMENTS_REPORT: '/PrintAppointmentsReport',
    PRINT_CLINICAL_SERVICES_INVENTORY_REPORT: '/PrintClinincalServicesInventoryReport',
    PRINT_AGINGS_REPORT: '/PrintAgingsReport',
    PRINT_PAYMENT_REPORT: '/PrintPaymentsReport',
    PRINT_PATIENT_REPORT: '/PrintPatientsReport',
    PRINT_INVOICE_REPORT: '/PrintInvoiceReport',
    PRINT_DISPENSE_REPORT: '/PrintDispenseReport',
    PRINT_STOCK_ADJUSTMENT_REPORT: '/PrintStockAdjustmetReport',

    //PROFILE
    PROFILE: '/Profile',
}