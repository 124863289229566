import React, { useState, Suspense, useEffect } from 'react';
import './App.scss';
import { withCookies, useCookies } from 'react-cookie';
import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'

import Loader from './components/CommonComponents/Loader/Loader';

import PageLayout from './components/CommonComponents/Layout/PageLayout/PageLayout';


import LocalStorageHelper from './utilities/LocalStorageHelper';
import { useSnackbar } from 'notistack';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            refetchOnReconnect: true,
            keepPreviousData: true,
            staleTime: 5 * 60 * 1000,
            cacheTime: 15 * 60 * 1000
            // networkMode: 'offlineFirst'
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            console.error('Caching Error: ', error.message);
        }
    }),
})

const App = () => {
    const userData = LocalStorageHelper.getUserData();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Use states
    const [toggledMenu, setToggledMenu] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(LocalStorageHelper.isLoggedIn());
    const [isDarkMode, setDarkMode] = useState(LocalStorageHelper.isDarkMode() === 'true');
    const [loadingState, setLoadingState] = useState(false);
    const [GettingSettings, setGettingSettings] = useState(false);

    //Variables
    const [cookies, setCookie, removeCookie] = useCookies(['navigatedPages']);
    if (isDarkMode) {
        document.body.classList.add('darkMode');
    }

    useEffect(() => {
        setGettingSettings(true);
        // getSystemGeneralSettings();
    }, []);

    // Event Handler Fuctions

    const loginChecker = async () => {
        let _proceed = false;
        let _pathName = window.location.pathname;
    }

    const updateActiveTab = (currentNavigatedPages, navigatedTo) => {
        for (const [pageKey, pageValue] of currentNavigatedPages.entries()) {
            pageValue.activeTab = (pageValue.linkName === navigatedTo.linkName ?
                true : false
            );
        }

        return currentNavigatedPages;
    }

    let pageLayoutProps = {
        updateActiveTab: updateActiveTab,
        darkMode: isDarkMode,
        setDarkMode: setDarkMode,
        toggledMenu: toggledMenu,
        setToggledMenu: setToggledMenu,
        loadingState: loadingState,
        setLoadingState: setLoadingState
    }

    //Rendering Functions

    return <QueryClientProvider client={queryClient}>
        <div className={`App${(isDarkMode ? ' darkMode' : '')}`}>
        {/* <Suspense fallback={<Loader />}> */}
        <Suspense fallback={<></>}>
                
                    <PageLayout {...pageLayoutProps} />

            {loadingState ?
                <Loader /> : ""
            }
        </Suspense>
    </div >
    
    </QueryClientProvider >
}

export default withCookies(App);