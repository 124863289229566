import {
   importRequest,
   getRequest,
} from "./apiClient";

//#region 
const PatientsImportUrl = 'patients/bulkimport';
const UserImportUrl = 'users/bulkimport';
const ClinicalServicesImportUrl = 'services/bulkimport';
// const InventoriesImportUrl = 'inventory/bulkimport';
const InventoriesImportUrl = 'clinicinventories/bulkimport';
const InventoriesMasterImportUrl = 'drugs/bulkimport';
const SuppliersImportUrl = 'suppliers/bulkimport';
const InsuranceImportUrl = 'insurances/bulkimport';

//#endregion 

const ImportFileService = {
   importPatients: async (param, headerRow) => {
      var res = await importRequest(PatientsImportUrl + "/" + headerRow.toString(), param);

      return res;
   },
   importUsers: async (param, headerRow) => {
      var res = await importRequest(UserImportUrl + headerRow.toString(), param);

      return res;
   },
   importClinicalServices: async (param, headerRow) => {
      var res = await importRequest(ClinicalServicesImportUrl + "/" + headerRow.toString(), param);

      return res;
   },
   importInventories: async (param, headerRow) => {
      var res = await importRequest(InventoriesImportUrl, param);

      return res;
   },
   importInventoriesMaster: async (param, headerRow) => {
      var res = await importRequest(InventoriesMasterImportUrl + "/" + headerRow.toString(), param);

      return res;
   },
   importSuppliers: async (param) => {
      var res = await importRequest(SuppliersImportUrl, param);

      return res;
   },
   importInsurances: async (param) => {
      var res = await importRequest(InsuranceImportUrl, param);

      return res;
   },
   // getServiceCategory: async (queryString) => {
   //    var _url = ServiceCategoryUrl + queryString;
   //    var res = await getRequest(_url);
   //    return res;
   // },
}

export default ImportFileService
